.popup .popup-title {
  margin-bottom: 16px;
}

.popup-body .popup-text {
  margin-bottom: 16px;
}

// .popup .popup-body {
//   text-align: center;
// }

.popup-body .popup-link {
  color: #E41472;
}

.form-group .save-password--center {
  justify-content: center;
}

.input .input-wrapper .empty {
  color: #a19e9e;
}

.form-subtitle {
  p {
    span {
      color: #E41472;
    }
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
}

.input-wrapper .input-icon-custom {
  top: calc(50% - 12px);
  transform: none;
}

.select-custom {
  z-index: 1;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  display: none;
}

.new-property-date-item__contetn div .react-datepicker {
  box-shadow: none;
}

.react-datepicker__month-select {
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.react-datepicker__year-select {
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.react-datepicker__header__dropdown--select {
  margin-top: -10px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #000000;
}

.react-datepicker__navigation--previous {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #EBEBF0;
  margin-left: 19px;
}

.react-datepicker__navigation-icon .react-datepicker__navigation-icon--previous {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 19px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color:#000;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -6px;
}

.react-datepicker__navigation--next {
  border-radius: 50%;
  background: #EBEBF0;
}

.react-datepicker__header {
  border-bottom: none;
}

.react-datepicker__day-names {
  padding: 20px 0 0 0;
  text-transform: uppercase;
}

.react-datepicker__day-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  opacity: 0.5;
}

.react-datepicker__navigation--next {
  margin-right: 20px;
}

.block-inline-custom {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
  cursor: pointer;
}

.block-position-calendar-custom {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 70px;
}
// .new-property-date-item__contetn div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week

.react-datepicker__day {
  line-height: 31px;
  width: 32px;
}

.react-datepicker__navigation-icon {
  top: 5px;
}

.react-datepicker__navigation-icon--next {
  left: 0;
}

.new-property .new-property-date {
  @media screen and (max-width: 1098px) {
    grid-gap: 0;
    gap: 0;
  }
}

.react-datepicker {
  margin: 0 auto;

  @media screen and (min-width: 621px) {
    margin: 0;
  }
}