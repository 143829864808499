.card-slider-box .swiper {
  .swiper-button-next {
    opacity: 0;
  }

  &:hover {
    .swiper-button-next {
      opacity: 1;
    }
  }

  .swiper-button-prev {
    opacity: 0;
  }

  &:hover {
    .swiper-button-prev {
      opacity: 1;
    }
  }

  .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }

  @media (max-width: 500px)  {
    .swiper-button-next {
      opacity: 0;
    }

    .swiper-button-prev {
      opacity: 0;
    }

    &:hover {
      .swiper-button-next {
        opacity: 0;
      }
    }

    &:hover {
      .swiper-button-prev {
        opacity: 0;
      }
    }
  }
}

.market-card-custom {
  cursor: pointer;
}
