.dropdown {
  &__active {
    display: block;
  }
}

.nav-item {
  cursor: pointer;
}

.button-transform .select-icon--arrow {
  transform: rotate(-90deg);
  transition: all 0.3s;
}

.button-activ .select-icon--arrow {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.wallet {
  height: 46px;
  transition: height 0.3s;
  overflow: hidden;
}

.activ-wallet {
  height: 281px;
  transition: height 0.3s;
  background: #f4f4f4
}
