.td .select .active {
  display: block;
}

.td .td-value--custom {
  text-transform: uppercase;
}

.center-custom {
  text-align: center;
}