.Toastify {
  width: auto;
  max-width: 511px;

  &__toast-container {
    width: auto;
    max-width: 511px;
  }

  &__toast {
    padding: 24px;
  }

  &__close-button--light {
    opacity: 1;
  }

  &__toast-body {
    padding: 0;
    align-items: flex-start;
    margin-right: 16px;
  }

  &__toast {
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
    border-radius: 10px;
  }
}

.notification__text {
  > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #25252E;

    > span {
      color: #E41472;
    }
  }

  > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    margin-top: 20px;
    width: 100px;
    height: 45px;
    background: #E41472;
    border-radius: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #FFFFFF;
  }
}

.notification__info {
  width: 100%;
}

.louder-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(37, 37, 46, 0.8);
}

.notification__text--custom {
  &::first-letter {
    text-transform: uppercase;
  }
}

.Toastify__toast-icon {
  display: inline !important;
}
