.input-wrapper .input-item--code-select {
  padding-left: 16px;
}

.PhoneInputInput {
  border: none;
  outline: none;
}

div .PhoneInputCountrySelectArrow {
  margin-left: 14px;
}

.input-wrapper .select__current-arrow {
  position: absolute;
  top: 27px;
  left: 46px;
}

.uploaded-photo .upload-action {
  background-color: rgb(187, 156, 156, 0.3);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.realty-photo-block .upload-action {
  background-color: rgb(187, 156, 156, 0.3);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.realty-photo-block .realty-photo {
  position: relative;
}

.input-wrapper--position {
  position: relative;
}

.input-phone-number {
  position: absolute;
  top: 18px;
  left: 86px;
  color: #b6b6b6;
  font-weight: 500;
  font-size: 16px;
}

.popup-window-custom .popup-window__inside {
  justify-content: start;

  @media (max-width: 1280px)  {
    justify-content: space-around;
  }
}

.photo-slider-box-custom {
  margin-top: 27px;

  // @media (max-width: 778px)  {
  //   margin-top: -20px;
  // }
}

.popup-window__inside--custom {
  padding-bottom: 60px;
}

.uploader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  background-color: rgb(0, 0, 0, 0.8);
}
