@import './error-msg';
@import './header';
@import './aboutDropDown';
@import './toastify';
@import './main-content';
@import './settings-custom';
@import './market-place-custom';
@import './auth-custom';
@import './sell-property-custom';
@import './transaction-custom';
@import './wallet-custom';
@import './login-custom';
@import './portfolio-custom';
@import './footer-custom.scss';
@import './iframe-custom';
@import './property-features';
@import './swiper-custom';
@import './home-page-custom';
@import './calendar-custom';
@import './faq';
@import './drop-file';

html {
  scroll-behavior: smooth;
}

.in-developing {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 248px);
}
