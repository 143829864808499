.select {
  &__drop-activ {
    display: block;
    z-index: 2;
  }
}

.nav-block__inner .authorization-btns .select {
  min-width: fit-content;
}

.authorization-btns .select--width .select__drop {
  min-width: 166px;
}

.wrapper .header--home .nav-btn__open path {
  stroke: #000;
}

.wrapper .header__container .nav-btn path {
  stroke: #000;
}

.wrapper .header--wait .nav-btn__open path {
  stroke: #fff;
}

.louder-wrapper-custom  {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 473px);
  width: 100%;
  min-height: 100%;
}

.mobile-menu {
  max-width: 341px;
  width: 100%;
}

.wrapper .header__container .nav-btn-custom-white svg path {
  stroke: #fff;
}

.header--custom {
  z-index: 10;
}

.main-custom {
  position: static;
}
