.form .back-to {
  margin: 0 auto;
}

.form__header .form-title--center {
  margin-top: 12px;
}

.checkbox__button {
  color: #E41472;
}