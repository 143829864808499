.home-section-custom {
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(37, 37, 46, 0.1);
	}
}

.container-home-custom {
	text-align: center;
}

.home-custom {
	margin: 0 auto;
	max-width: 1024px;
	width: 100%;
}

@media screen and (max-width: 767px) {
	.home__btn--custom .button {
		max-width: 275px;
		width: 100%;
	}
}

@media screen and (max-width: 991px) {
	.home {
		padding-right: 0;
	}
}

.wrapper-button-header {
	display: none;

	.nav-btn__open path {
		stroke: #fff;
	}
	.nav-btn__close path {
		fill: #fff;
	}
}

@media screen and (max-width: 991px) {
	.wrapper-button-header {
		display: flex;
		align-items: center;
		gap: 20px;
		.nav-btn__open path {
			stroke: #fff;
		}
		.nav-btn__close path {
			fill: #fff;
		}
	}
}

@media screen and (max-width: 991px) {
	.main .main-section-custom {
		padding: 90px 0 60px 0;
	}
}

.wrapper-button-header .nav-item-custom {
	padding: 3px 5px;
}

.terms-wrapper .terms-block .subtitle-custom {
	font-weight: 700;
	margin: 10px 0;
}

.terms-wrapper .terms-block .p-custom-one {
	margin: 10px 0;
}

.ul-custom-list {
	list-style-type: disc;
	padding-left: 55px;

	li {
		margin-bottom: 15px;
	}
}

.strong-custom {
	font-weight: 600;
}

.terms-wrapper .terms-block .light-custom {
	font-weight: 400;
}

.swiper-slide-custom-home {
	max-width: 300px;
}

.get-started__title-custom {
	text-decoration: underline;
}

.video-poster--fit {
	background-image: url(../../images/video-poster.webp);
}


.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	margin: 0;
	background: #fff;
	border: 1px solid #d9d9d9;
}

.react-multi-carousel-dot-list {
	gap: 10px;
}

.swiper-wrapper.swiper-wrapper--style {
	transform: translate3d(-1947px, 0px, 0px) !important;
	transition-duration: 0ms !important;
}

.swiper-slide .review {
	margin-left: 10px;
	margin-right: 10px;
}

@media screen and (max-width: 1024px) {
	.latest-update-slider {
		width: calc(100% + 32px);
		margin-left: -16px;
		padding: 0 16px;
	}
}
