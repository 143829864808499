.drop-file-input {
	position: relative;
	width: 400px;
	height: 200px;
	border: 2px dashed var(--border-color);
	border-radius: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: var(--input-bg);
}

.drop-file-input input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
	opacity: 0.6;
}

.drop-file-input__label {
	text-align: center;
	color: var(--txt-second-color);
	font-weight: 600;
	padding: 10px;
}

.drop-file-input__label img {
	width: 100px;
}

.drop-file-preview {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
}

.drop-file-preview p {
	font-weight: 500;
}

.drop-file-preview__title {
	margin-bottom: 20px;
}

.drop-file-preview__item {
	position: relative;
	margin-right: 20px;
}

.drop-file-preview__item img {
	width: 40px;
}

.drop-file-preview__item__info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
}

.drop-file-preview__item__del {
	background-color: var(--box-bg);
	width: 1rem;
	height: 1rem;

	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(50%, -50%);
	cursor: pointer;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
	opacity: 1;
}
