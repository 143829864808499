.iframe {
	&__wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		display: flex;
		justify-content: center;
		background: rgba(37, 37, 46, 0.8);
	}

	&__content {
		width: 100%;
		height: 100%;
		z-index: 10;
		// margin-right: -30px;
	}

	&__close {
		position: absolute;
		z-index: 11;
		right: 7px;
		top: 10px;
		width: 30px;
		height: 30px;
		border-radius: 8px;
		cursor: pointer;
		background-color: azure;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}
}

.step-custom {
	display: block;
	word-break: break-all;
}

.doc-frame {
	iframe {
		max-width: 100%;
		width: 100%;
		height: calc(100vh - 258px);
	}
}

.doc-frame-custom {
	width: 100%;
}

.popup-window .popup-window__inside .doc-frame .doc-frame-height-custom {
	height: calc(100vh - 152px);
}

.landing-video {
	video {
		height: auto;
	}
}

@media (hover: none) {
	.landing-video--mob {
		display: flex;
		position: absolute;
		left: -999px;
	}
}

@media (hover: none) and (max-width: 767px) {
	.landing-video--mob {
		position: relative;
		left: 0;
	}
}
