.container .market-filters {
  position: relative;
  z-index: 4;
}

.market-filters .filter .select {
  z-index: unset;
}

.container-wrapper {
  padding: 88px 16px 0;
}

.map-container {
  width: 100%;
  height: 100%;
}

.swiper-slide--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.realty-overview__text-height {
  // height: 93px;
  overflow: hidden;
}

.realty-overview__text-height-full {
  height: 100%;
  overflow: hidden;
  transition: height 0.5s;
}

.admin-section .container header {
  margin-top: -81px;
  border: none;
  position: relative;
  z-index: 3;
  background-color: white;
}

.container .popup-window {
  overflow: hidden;
  height: 100vh;
}

.photo-slider .photo-slide {
  max-width: 100%;
}

.container .popup-window {
  &__inside {
    max-width: 1334px;
    margin: 0 auto;
  }
}

.photo-slide img {
  max-height: 545px;
}

.photo-slider-box {
  position: relative;
}

.swiper .slider-arrow--next {
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
  z-index: 5;
  max-height: 60px;
  max-width: 60px;
  width: 100%;
  height: 100%;
}

.swiper .slider-arrow--prev {
  position: absolute;
  left: 0;
  top: calc(50% - 30px);
  z-index: 5;
  max-height: 60px;
  max-width: 60px;
  width: 100%;
  height: 100%;
}

.td .status-custom-0 {
  color: #E41472;
}

.td .status-custom-1 {
  color: #25A353;
}

.td .status-custom-2 {
  color: #C51918;
}

.swiper .swiper-wrapper {
  display: flex;
  align-items: center;
  max-width: 1265px;
}

.button-custom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 110px;
  grid-gap: 8px;
  gap: 8px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: #F3F3F3;

  &:not([disabled]):hover{
    background: #d81159;
    color: white;
  }
}

.button-custom--color {
  background: #d81159;
  color: white;
}

.wrapper-images-custom {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 11;

  display: flex;
  justify-content: center;
  background-color: #fff;
}

.admin-custom {
  margin: 59px 0;
  height: calc(100vh - 194px);
}

.inner-image {
  width: 100%;
}

.object-action-custom {
  width: 45px;
  height: 45px;
}

.back-btn-custom-color {
  color: #25252E;
}

.realty-info-box__item-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-title-custom {
  position: relative;
  margin-top: 65px;

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    top: -26px;
    background: #E6E6E6;
    display: block;
  }
}

.realty-map-custom {
  margin-left: 0;
}

@media screen and (max-width: 767px) {
  .realty-map-custom {
    width: 100%;;
  }
}

.market-layout--custom {
  display: flex;
  width: 100%;
  height: calc(100vh - 536px);
  align-items: center;
  justify-content: center;
}

.checkbox__label--custom {
  position: relative;
}

.input-position-custom {
  top: 30px;
}

.button-custom-1 {
  max-width: 200px;
  height: 45px;
  background: #d81159;
  border-radius: 14px;
}

.realty-info-custom {
  white-space: nowrap;

  @media (max-width: 393px)  {
    white-space: normal;
  }
}

.realty-info-text-custom {
  text-align: end;
}

.realty-invest .realty-invest__btn-custom {
  pointer-events: none;
  cursor: default;
  border-radius: 14px;
  background-color: #B6B6B6;
}

.features-list__group-custom {
  max-width: 100%;
}

.market-card__top .object-label--soon {
  color: #fff;

  .object-label__dot {
    background-color: #fff;
  }
}

.realty-actions--custom .object-label--soon {
  color: #fff;

  .object-label__dot {
    background-color: #fff;
  }
}

.realty-doc-custom {
  a {
    word-break: break-all;
  }
}

.container .main-section-custom {
  padding: 0;
}

.display-custom {
  justify-content: center;
}

.header-custom {
  border-bottom: none
}

.slider-header {
  z-index: 7;
}