input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.button {
  gap: 8px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: var(--main-color);
  &:hover {
    background: darken(#E41472, 10%)
  }
  &--authorization {
    font-weight: 500;
  }
  &--type2 {
    background: transparent;
    border: 1px solid var(--border-color-main);
    color: var(--text-color-main);        
    &:hover {
      background: transparent;
      border-color: var(--main-color);      
    }
  }
  &--type3 {
    background: var(--accent-third-color);
    &:hover {
      background: var(--accent-secondary-color);    
    }
  }
  &--type4 {
    background: #fff;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    .fill path {
      fill: var(--main-color);
    }
    &:hover {
      background: var(--main-color);
      color: #fff;
      .fill path {
        fill: #fff;
      }
    }
  }
  &--type5 {
    background: #fff;
    border: 1px solid var(--main-color);
    color: var(--text-color-main);
    .fill path {
      fill: var(--main-color);
    }
    &:hover {
      background: #fff;
      color: var(--main-color);
      .fill path {
        fill: #fff;
      }
    }
  }
  &--type6 {
    background: var(--button-bg2);    
    color: var(--text-color-main);    
    &:hover {
      background: var(--button-bg2);
      color: var(--main-color);      
    }
  }
  &:disabled {
    background: #B6B6B6;
    opacity: 1;
    color: #fff;
    &:hover {
      background: #B6B6B6;
    }
  }
  &--width-auto {
    width: auto;
    min-width: auto;
  }
  &--long {
    width: 275px;
  }
  &--medium {
    width: 200px;
  }
  &--width-210 {
    width: 210px;
  }
  &--width-150 {
    width: 150px;
  }
  &--small {
    width: 125px;
  }
  &--mob-full-width {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  &--smaller-height {
    height: 38px;
  }
  &--bigger-height {
    height: 60px;
  }
  &--action-type {
    width: 45px;
    height: 45px;
    min-width: unset;
    padding: 0 8px;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--fs-14 {
    font-size: 14px;
  }
  a {
    color:#fff;
  }
}

.btn-icon {
  display: flex;
  width: 21px;
  .fill path, .stroke path {
    transition: 0.2s;
  }
  &--width-24 {
    width: 24px;
  }
}

.input-icon {
  width: 24px;
  &--calendar {
    width: 24px;
  }
  .stroke-type path {
    stroke: var(--text-color2);
  }
  &--color {
    .fill path {
      fill: var(--main-color);
    }
  }
}

.input {   
  margin-bottom: 16px;
  &--no-mb {
    margin-bottom: 0;
  }  
  &--error {
    .input-item {
      border-color: #D1513A;
    }
  }
}

.input-item {       
  height: 60px;
  border: 1px solid var(--border-color-main);  
  border-radius: 14px;
  padding: 0 16px;  
  font-weight: 500;
  font-size: 16px;
  color: var(--text-main-color);
  @include input-placeholder {
    color: var(--text-secondary-color);
    transition: all 0.15s ease;
    font-size: 16px;
    font-weight: 500;    
    color: var(--text-color2);
  }
  &:focus {
    border-color: var(--main-color);
    border-width: 2px;
  }
  &--small-height {
    height: 45px;    
    font-size: 14px;
    @include input-placeholder {     
      font-size: 14px; 
    }
  }
  &--left-icon {
    padding-left: 50px;
  }
  &--code-select {
    padding-left: 110px;
  }
  &--code-select2 {
    padding-left: 135px;
  }
  &--pr-125 {
    padding-right: 125px;
    @media screen and (max-width:767px) {
      padding-right: 115px;
    }
  }
  &--pr-75 {
    padding-right: 75px;    
  }
}

.textarea {
  border: 1px solid var(--border-color-main);  
  border-radius: 14px;
  padding: 10px 16px;    
  textarea{
    min-height: 100px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-main-color);
    resize: none !important;
    @include input-placeholder {
      color: var(--text-secondary-color);
      transition: all 0.15s ease;
      font-size: 16px;
      font-weight: 500;    
      color: var(--text-color2);
    }
    @media screen and (max-width: 767px) {
      min-height: 130px;
    }
  }
}

.show-pass {
  width: 24px;
  svg path {
    fill: var(--text-color-main);
  }
}

.input-action, .input-icon-text, .input-coin {
  position: absolute;
  right: 16px;
  left: unset;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 500;
  color: var(--main-color);
  &--right-75 {
    right: 75px;
  }
}

.input-icon-text  {
  right: 35px;
  margin-right: 10px;
}

.input-coin {
  color: var(--text-color2);
  &--color2 {
    color: var(--text-color-main);
  }
}

.select {
  
  height: 45px;
  border-color: var(--border-color-main);
  border-radius: 14px;
  &__current {
    justify-content: flex-start;
    gap: 10px;
    padding: 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: var(--text-color-main);
    &--unselect {
      color: var(--text-color2);
    }    
  }
  &__current-arrow {
    margin-left: auto;
    flex: 0 0 12px;
    min-width: 12px;
  }
  &__drop-scroll {
    max-height: 360px;
  }
  &__drop {    
    border: 1px solid #D9D9D9;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 0;
    ul {
      >li {
        display: flex;
        flex-direction: column;
        &.active {
          background: #F8F8F8;
        }
        >a, >button {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 11.5px 16px;
          font-weight: 500;
          font-size: 14px;  
          line-height: 1.8;
          &:hover {

          }
        }
        &+li {
          border-top: 1px solid #D9D9D9;
        }
        &:first-child {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          >a, >button {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }
        &:last-child, &:only-child {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          >a, >button {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }
      &.sub-drop {
        display: none;
        padding-left: 55px;
        padding-bottom: 10px;
        &.active {
          display: block;
        }
        >li {
          border: none;
          >a, >button {
            padding-left: 0;
            padding-right: 0;
            border-radius: 0 !important;
            transition: 0.2s;
            &:hover {
              background: transparent;
              color: var(--main-color);
            }
          }
        }
      }
    }
  }
  &--user {
    .select__drop-scroll {
      max-height: unset;
      
    }
  }
  &--phone {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    border: none;
    .select__current {
      padding: 0;
      border: none;
    }
    .select__drop {
      width: 105px;
      top: calc(100% + 16px);
      left: -16px;
    }
    .select__drop-scroll {
      max-height: 295px;
    }
  }
  &--phone-width2 {
    width: 104px;
    .select__drop {
      width: 147px;
    }
  }
  &--sidebar-nav {
    height: auto;
    border: none;
    @media screen and (max-width:767px) { 
      height: 60px;
      border: 1px solid var(--border-color-main);
    }
    .select__current {
      display: none;
      @media screen and (max-width:767px) { 
        display: flex;
      }
    }
    .select__drop {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      box-shadow: none;
      @media screen and (max-width:767px) { 
        display: none;
        position: absolute;
        top: calc(100% + 16px);
      }
      ul >li {
        &.active {
          @media screen and (max-width:767px) { 
           background: transparent;
          }
        }
        >a, >button {
          &:hover {
            background: #F8F8F8;
          }
        }
      }
    }
  }
  &--td-filter {
    display: inline-flex;
    height: auto;
    border: none;
    .select__current {
      width: auto;
      padding: 0;      
      font-weight: 600;
    }

    .select__drop {
      min-width: 120px;
      right: 0;
      left: unset;
      @media screen and (max-width: 767px) {
        left: 0;
        right: unset;
      }
    }
  }
  &--left-drop {
    .select__drop {
      left: 0;
      right: unset;
    }
  }
  &--small-drop {
    .select__drop {
      width: 147px;
    }
  }
  &--networks {
    .select__current {
      gap: 4px;
      .network-name {
        margin-top: 2px;
      }
    }
    .select__drop {
      width: 247px;
      @media screen and (max-width:767px) {
        width: 100%;
      }
      ul {        
        >li {
          >a, >button {
            gap: 4px;
          }
        }
      }
    }    
  }
  &--type2 {
    border-color: var(--main-color);
  }
  &--bigger {
    height: 60px;
  }
}

.select-icon {
  display: flex;
  width: 22px;
  .fill path {
    fill: #9E9E9E;
  }
  .stroke path {
    stroke: #9E9E9E;
  }
  &--dark {
    .fill path {
      fill: var(--text-color-main);
    }
    .stroke path {
      stroke: var(--text-color-main);
    }
  }
  &--color {
    .fill path {
      fill: var(--main-color);
    }
    .stroke path {
      stroke: var(--main-color);
    }
  }
  &--arrow {
    margin-left: auto;
    flex: 0 0 12px;
    min-width: 12px;
  }
}

.block-title {  
  font-weight: 600;
  font-size: 34px;
  line-height: 1.5;   
  &--mr-auto {
    margin-right: auto;
  }
  &--smaller {
    font-size: 24px;
  }
  &--fs-22 {
    font-size: 22px;
  }
  &--fs-26 {
    font-size: 26px;
  }
  @media screen and (max-width: 1199px) {
    //font-size: 24px;
  }
  @media screen and (max-width: 991px) {
    //font-size: 24px;
  }
  @media screen and (max-width: 767px) {
    //font-size: 20px;
  }
  &--mob-16 {
    @media screen and (max-width: 991px) {
      font-size: 24px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  &--mob-24 {
    @media screen and (max-width: 991px) {
      font-size: 24px;
    }
  }
  &--balance {
    display: flex;
    gap: 0 40px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &--mob-center {
    @media screen and (max-width: 767px) {
      align-items: center;
      text-align: center;
    }
  }
  &--color2 {
    color: var(--main-color);
  }
}

.block-subtitle {
  font-size: 18px;
  line-height: 1.78;
  text-align: center;
  color: var(--text-color2);
  a {
    color: #3D58DB;
  }
  &--fixed-size {
    max-width: 290px;
    margin-left: auto;margin-right: auto;
  }
  &--type2 {
    font-size: 20px;
    color: var(--text-color-main);
  }
  &--type3 {
    font-size: 16px;
    color: var(--text-color-main);
  }
}

.section-title {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 180%;
  &--uppercase {
    text-transform: uppercase;
  }
  @media screen and (max-width: 1199px) {
    font-size: 36px;
  }
  @media screen and (max-width: 991px) {
    font-size: 30px;
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    padding: 0 15px;
  }
}

.popup-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  width: 18px;
  svg {
    width: 100%;
  }
  &--type2 {
    transform: none;
    right: 24px;
    top: 24px;
  }
}

.popup-reset {
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  display: flex;
  width: 24px;
}

.popup {
  padding: 50px 24px;
  &--type2 {
    padding: 40px 24px;
  }
  &--smaller {
    width: 431px;
  }
  &--bigger {
    width: 634px;
  }
  &--big {
    width: 700px;
  }
}

.popup-header {
  position: relative;
  min-height: 32px;
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: var(--text-four-color);
  }
  &+.popup-body {
    margin-top: 24px;
    &--type2 {
      margin-top: 16px;
    }
  }
  .success-icon {
    margin: 0 auto;
  }
}

.popup-body  {
  margin-top: 0;
  
}

.popup-footer {
  display: flex;
  gap: 16px;
  margin-top: 18px;
  &--more-mt {
    margin-top: 24px;
  }
  &--mob-column {
    @media screen and (max-width:575px) {
      flex-direction: column;
    }
  }
}

.popup-text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 165%;  
  &--center {
    text-align: center;
  }
  &--mt {
    margin-top: 16px;
  }
}


.checkbox {
  margin-bottom: 16px;
  &__item {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border: 1.5px solid var(--border-color-main);
    border-radius: 7px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: var(--text-color-main);
    a {
      color: var(--main-color);
      text-decoration: none !important;
    }
  }
  input:checked ~ .checkbox__item {
    border-color: var(--main-color);
    border-width: 2px;
    background:  transparent;
  }
  input:checked ~ .checkbox__item .checkbox__item-icon{
    svg path {
      stroke: var(--main-color);
    }
  }
  &--type2 {
    
    .checkbox__label {
      align-items: flex-start;
    } 
    .checkbox__item {
      margin-top: 6px;
      width: 25px;
      flex: 0 0 25px;
      height: 25px;
      border-radius: 0;
      border: 2px solid #000000;
    }
    .checkbox__text {
      margin-left: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
    }
    .checkbox__item-icon {
      width: 15px;
    }
    input:checked ~ .checkbox__item {
      border-color: #000 !important;
    }
  }
  &--f-start {
    .checkbox__label {
      align-items: flex-start;
    } 
  }
}

.radio {
  margin-bottom: 8px;
  &__item {
    width: 20px;
    flex: 0 0 20px;
    height: 20px;
    border-color: #9E9E9E;
    &:after {
      display: none;
    }
  }
  &__text {
    margin-left: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    color: var(--text-color-main);
  }
  input:checked ~ .radio__item {
    background: var(--main-color);    
  }
  &--box-type {
    .radio__label {
      border: 1px solid #CFCFCF;
      padding: 16px 24px;
      border-radius: 15px;
    }
    .radio__text {
      font-weight: 400;
    }
    input:checked ~ .radio__item {
      background: var(--main-color);    
    }
    &.active {
      .radio__label {
        border-color: var(--main-color);
      }
    }
  }
}

.notification-wrapper {
  position: fixed;
  top: 110px;
  right: 34px;
  @media screen and (max-width: 767px) {
    top: 68px;
    right: 16px;
    width: calc(100% - 32px);
  }
}

.notification {
  display: flex;
  align-items: flex-start;
  gap: 16px;  
  width: 510px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 10px;
  @media screen and (max-width: 767px) {
    
    width: 100%;
  }
  @media screen and (max-width: 575px) {    
    flex-direction: column;
  }
  &__close {
    top: 24px;
    right: 24px;
    width: 20px;
    button {
      display: flex;
    }
  }
  &__icon {
    width: 20px;
    min-width: 20px;
    margin-top: 2px;
    @media screen and (max-width: 575px) {    
      margin-top: 0;
    }
    svg {
      width: 100%;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color-main);
  }
  &__text {
    max-width: 385px;
    margin-top: 12px;
    line-height: 1.75;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color-main);
    a {
      color: var(--main-color);
    }
    button {
      margin-top: 12px;
      padding: 0 24px;     
    }
  }

}

.tooltip-item {
  width: 16px;
  height: 16px;
}

.tooltip {
  @media screen and (max-width: 475px) {
    max-width: 200px;
  }
}

.table-body {
  margin-top: 27px;
  line-height: 180%;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
  .tr {    
    border: none;
    &+ .tr {
      margin-top: 16px;
    }
  }
  .tr {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;          
    }
  } 
  .td {
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 4px;
      &+.td {
        margin-top: 8px;
      }
    }
  }
 
}

.table {
  border-color: #CFCFCF;
  border-radius: 15px;
  padding: 24px 12px;
  .td {
    padding: 0 12px;
  }
  .table-body  {

  }
}

.table-header {
  line-height: 180%;
  .tr {
    border: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.td--right {
  .select--td-filter {
    justify-content: flex-end;
  }
}

.td--center {
  text-align: center;
  @media screen and (max-width: 1199px) {
    text-align: left;
  }
}

.td-name {
  font-weight: 600;
  font-size: 16px;
}

.td-value {
  font-weight: 600;
  font-size: 18px;     
  line-height: 180%;
  &--medium {
    font-size: 16px;
    font-weight: 500;
  }
  a {
    text-decoration: underline;
    color: #3D58DB;
  }
}

.td-hidden-name {
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color-main);
  @media screen and (max-width: 767px) {
    display: flex;
    min-width: 120px;
  }
}

.popup-window {
  z-index: 200;
  background: rgba(37,37, 46,0.8);   
  &__inside {
    flex-direction: column;
    align-items: center;justify-content: center;
    padding: 10px;    
  }
  &--doc {
    .popup-window__inside {
      justify-content: flex-start;
      padding: 0 0 10px 0;
    }
  }
}

.upload-file {
  &__title {
    font-size: 16px;
    color: var(--text-color-main);
    @media screen and (max-width: 475px) {
      font-size: 14px;
    }
  }
  &__text {
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color2);
    @media screen and (max-width: 475px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 16px;
  }
  &__icon {
    width: 24px;
  }
}

.upload-file-box {
  width: 100%;
  height: 202px;  
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D5D5D5FF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='28' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;    
  &--pink-border {
    background-image: none;
    border: 2px solid #FAC3DC;
  }
  &--no-border {
    background-image: none;
  }
  &--red-border {
    position: relative;
    right: -1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D1513A' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='28' stroke-linecap='square'/%3e%3c/svg%3e");

    //background-image: url("data:image/svg+xml,%3Csvg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='201' height='201' rx='7.5' stroke='%23D1513A' stroke-dasharray='6 6'/%3E%3C/svg%3E%0A");
  }
  &--disabled {
    .upload-file__icon {
      svg {
        path {
          stroke: #9E9E9E;
        }
      }
    }
    .upload-file__title {
      color: #9E9E9E;
    }
  }
}

.switch {
  &__toggler {
    width: 40px;
    height: 24px;
    border-radius: 12px;
    border: 3px solid #000000;
    &::before {
      width: 8px;
      height: 8px;
      background: transparent !important;
      border: 3px solid #000;
    }
  }
  input:checked ~ .switch__toggler {
    border-color: var(--main-color);
    &:before {
      left: 18px;
      border-color: var(--main-color);
    }
  }
}

.pagination-block {
  margin-top: 40px;
}

.pagination {
  gap: 10px 30px;
  margin: 0;
  @media screen and (max-width:767px) {
    //gap: 10px 25px;
  }
  >li {
    display: flex;
    padding: 0;
    >a, >button {
      margin-top: 0;
      height: auto;
      width: auto;
      min-width: unset;
      background: none !important;
      border: none !important;    
      padding: 0;  
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-main);      
    }    
    &.arrow {
      .stroke path {
        stroke: var(--text-color-main);
      }
      &.disabled {
        svg {
          opacity: 1;
        }
        .stroke path {
          stroke: #D9D9D9;
        }
        
      }
    }
    &:not(.disabled){
      &:hover, &.active {
        >a,>button {
          background: none;
          color: var(--main-color);              
        }     
        .stroke path {
          stroke: var(--main-color);    
        }     
      }      
      
    }
  }
}

.faq-list {
  max-width: 100%;  
}

.faq {
  margin-top: 24px;
  padding: 0 0 24px 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #D9D9D9;
  &__header-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 180%;
  }
  &__header-btn {
    flex: 0 0 14px;
    max-width: 14px;
  }
  &__text {
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #000;
  }
  &+.faq {
    margin-top: 24px;
  }
  &.active {
    .faq__text {
      margin-top: 0;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .plus-vertical {
      display: none;
    }
  }
  &--type2 {
    padding-bottom: 0;
    border-bottom: none;
    .faq__header-title {
      color: var(--main-color);
    }
  }
}
