.select {
  &__current--select {
    outline: none;
    background: inherit;
    border: inherit;
    border-radius: 14px;
    color: #000;
  }

  &__option-custom {
    color: #000;
  }
}

.select .select__drop--active {
  display: block;
}

.select {
  &__current--activ {
    color: #000;
  }
}

.order-option {
  &__value--padding {
    padding-left: 20px;
  }
}

.td-upper .td-status {
  &::first-letter {
    text-transform: capitalize;
  }
}

.td-status--completed {
  color: #25A353;
}

.td-status--processing {
  color: #CA8000;
}

.td .td-status--error {
  color: #ff0000;
}

.td-status--canceled {
  color: #D1513A;
}

.td-status--admin_check {
  color: #637004;
}

.td-status--waiting_user {
  color: #9febbb;
}

.td-status--waiting_admin {
  color: #00ff5e;
}

.td-status--waiting_bank {
  color: #062210
}

.table-header .tr .td .select--small-drop {
  z-index: unset;
}

.td-status--waiting {
  color: #CA8000;
}

ul li .text-upper {
  text-transform: capitalize;
}

.td-hidden-name-upper {
  text-transform: uppercase;
}

.td .td-hidden-name-upper {
  text-transform: uppercase;
}

.td-value-first-letter-up {
  &::first-letter {
    text-transform: capitalize;
  }
}

.td-value-flex-start {
  text-align: start;
}

.td-value-custom {
  white-space: nowrap;
}

.success-icon-center-custom {
  text-align: center;
}

.order-option .order-option-custom-adress {
  word-break: break-all;
  margin-left: 15px;
}

.table .table-header .tr-custom-balance {
  grid-template-columns: 2fr 2fr 2fr 3.5fr;
}

.table .table-body .tr-custom-balance {
  grid-template-columns: 2fr 2fr 2fr 3.5fr;
}

.order-option .order-option__value-custom {
  @media screen and (max-width: 767px) {
    text-align: left;
    margin: 0;
    padding: 0;
  }
}