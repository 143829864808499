@import 'reset.scss';
@import 'ui-kit.scss';
@import 'ui-change.scss';
@import 'swiper.scss';

:root {
  --main-bg                : #FFF;
  --block-bg               : #FFF;
  --block-bg2              : #25252E;
  --block-bg3              : #F3F3F3;

  --button-bg2             : #F3F3F3;

  --main-color             : #E41472;
  --accent-color           : #1A6BD8;
  --accent-secondary-color : #3FDBB1;
  --accent-third-color     : #31BDAA;

  --text-color-main        : #25252E;
  --text-color2            : #9E9E9E;
  --text-color3            : #706D6D;
  --text-color-white       : #FFF;

  --border-color-main      : #D5D5D5;
  --border-color2          : #E6E6E6;

  --border-width           : 1px;
  --border-radius          : 4px;
  --bg-color               : #FFF;
}

* {
  -webkit-font-smoothing : antialiased;
}

body {
  background             : var(--main-bg);
  font-family            : 'Poppins', sans-serif;
  line-height            : 1.5;
  font-size              : 14px;
  font-weight            : 400;
  color                  : var(--text-color-main);
  -webkit-font-smoothing : antialiased;
}

button,
input,
label,
textarea {
  font-family : 'Poppins', sans-serif;
}

svg {
  width     : auto;
  max-width : 100%;
}

.wrapper {
  position       : relative;
  display        : flex;
  flex-direction : column;
  min-height     : 100vh;
  overflow-x     : hidden;
}

main {
  position       : relative;
  display        : flex;
  flex-direction : column;
  flex           : 1 1 auto;
}

.main--realty-banner {
  & + .footer {
    @media screen and (max-width : 767px) {
      margin-bottom : 81px;
    }
  }
}

.container {
  max-width : 100%;
  width     : 1310px;
  padding   : 0 16px;
  margin    : 0 auto;

  &--relative {
    position : relative;
  }
}

.header {
  background    : var(--main-bg);
  padding       : 15px 20px;
  border-bottom : 1px solid var(--border-color2);

  &__container {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    max-width       : 100%;
    width           : 1310px;
    margin          : 0 auto;
    padding         : 0 16px;

    &--full {
      width : 100%;
    }
  }

  @media screen and (max-width : 1199px) {
    display     : flex;
    align-items : center;
    padding     : 10px 0;
    height      : 52px;
  }

  &--gallery-type {
    min-height : 60px;
    padding    : 15px 0;
    @media screen and (max-width : 767px) {
      .button {
        height : 40px;
      }
    }
  }

  &--home {
    .header-left {
      flex-grow : 0;
    }

    .nav-block {
      flex : 1;

      &__inner {
        flex : 1;
        gap  : 0;
      }
    }

    .main-nav {
      margin-left : auto;
      gap         : 43px 32px;
    }

    .nav-item__link {
      font-weight : 500;
    }

    .authorization-btns {
      margin-left : auto;
      gap         : 16px 8px;

      .button--type2 {
        border : none !important;
      }

      @media screen and (max-width : 1199px) {
        margin-right : auto;
      }
    }

    .nav-btn__open path {
      stroke : var(--main-color);
    }

    .nav-btn__close path {
      fill : var(--main-color);
    }
  }
}

.autorized {
  display     : flex;
  align-items : center;
  gap         : 0 24px;
  margin-left : 10px;
}

.header-left {
  display     : flex;
  align-items : center;
  flex-grow   : 1;
  @media screen and (max-width : 1199px) {
    flex : 1;
  }
}

.nav-btn {
  display : none;
  width   : 24px;

  &__open {
    svg path {
      stroke : var(--text-color-main);
    }
  }

  &__close {
    svg path {
      fill : var(--text-color-main);
    }
  }

  @media screen and (max-width : 1199px) {
    display : flex;
    &__close {
      display : none;
    }
    &.active {
      .nav-btn__close {
        display : flex;
      }

      .nav-btn__open {
        display : none;
      }
    }
  }
}

.nav-open {
  position : relative;
  overflow : hidden;
}

.logo {
  display     : flex;
  align-items : center;
  width       : 70px;
  @media screen and (max-width : 1199px) {
    width : 54px;
  }
}

.header-right {
  display     : flex;
  align-items : center;
  gap         : 0 16px;
}

.authorization-btns {
  display : flex;
  gap     : 24px 16px;
  @media screen and (max-width : 1199px) {
    justify-content : center;
    flex-direction  : column;
    align-items     : center;
    order           : -3;
  }

  .button {
    width     : 100px;
    min-width : 100px;
    @media screen and (max-width : 1199px) {
      width     : 275px;
      min-width : unset;
    }
  }

  .select {
    min-width : 166px;
    @media screen and (max-width : 1199px) {
      width : 260px;
    }
    @media screen and (max-width : 767px) {
      width : 100%;
    }

    &__drop {
      top       : calc(100% + 35px);
      min-width : 340px;
      right     : -30px;
      left      : unset;
      overflow  : hidden;
      @media screen and (max-width : 1199px) {
        right     : unset;
        left      : 50%;
        transform : translateX(-50%);
        max-width : 100%;
        min-width : unset;
      }
      @media screen and (max-width : 767px) {
        width : 100%;
        top   : calc(100% + 15px);
      }
    }
  }
}

.nav-block {
  display : flex;
  margin  : 0 auto;

  &__inner {
    display : flex;
    gap     : 0 32px;
    @media screen and (max-width : 1199px) {
      width          : 100%;
      flex-direction : column;
    }
  }

  @media screen and (max-width : 1199px) {
    display    : none;
    position   : fixed;
    top        : 52px;
    left       : 0;
    height     : calc(100vh - 52px);
    width      : 100%;
    background : var(--main-bg);
    overflow   : auto;
    z-index    : 99;
    padding    : 24px 15px;
    &.active {
      display : block;
    }
  }
}

.nav-item {
  position : relative;
  display  : flex;
  @media screen and (max-width : 1199px) {
    width           : 100%;
    justify-content : center;
    flex-wrap       : wrap;
  }

  &__link {
    display     : flex;
    align-items : center;
    gap         : 8px;
    font-size   : 16px;
    font-weight : 600;
    color       : var(--text-color-main);
    transition  : 0.2s;
    @media screen and (max-width : 1199px) {
      width           : 100%;
      justify-content : center;
    }

    &:hover {
      color : var(--main-color);

      .nav-item__link-arrow svg path {
        fill : var(--main-color);
      }
    }
  }

  &__link-arrow {
    display    : flex;
    width      : 12px;
    transition : 0.2s;

    svg path {
      stroke     : var(--icon-second-color);
      transition : 0.2s;
    }
  }

  &.active {
    .dropdown {
      display : block;
      z-index : 10;
    }

    .nav-item__link-arrow {
      transform : rotate(-180deg);
    }
  }
}

.main-nav {
  display : flex;
  gap     : 43px 32px;
  @media screen and (max-width : 1366px) {
    gap : 16px;
  }
  @media screen and (max-width : 1199px) {
    flex-direction : column;
    width          : 100%;
    gap            : 40px 0;
    margin-top     : 32px;
  }
}

.dropdown {
  display       : none;
  position      : absolute;
  background    : #FFF;
  border        : 1px solid #D9D9D9;
  box-shadow    : 0 8px 15px rgba(0, 0, 0, 0.1);
  border-radius : 15px;

  &.active {
    display : block;
  }

  &--nav {
    width : 190px;
    top   : calc(100% + 40px);
    left  : 0;
    @media screen and (max-width : 1199px) {
      position  : relative;
      top       : 0;
      margin    : 15px auto 0 auto;
      width     : 100%;
      max-width : 343px;
    }
  }
}

.dropdown-list {
  > li {
    & + li {
      border-top : 1px solid #D9D9D9;
    }

    &:first-child {
      > a,
      > button {
        border-top-left-radius  : 15px;
        border-top-right-radius : 15px;
      }
    }

    &:last-child {
      > a,
      > button {
        border-bottom-left-radius  : 15px;
        border-bottom-right-radius : 15px;
      }
    }

    > a,
    > button {
      display     : flex;
      padding     : 12px 16px;
      color       : var(--text-secondary-color);
      transition  : 0.2s;
      font-weight : 500;
      font-size   : 14px;

      &:hover {
        background : #F4F4F4;
      }
    }

    &.active {
      > a,
      > button {
        background : #F4F4F4;
      }
    }
  }
}

.footer {
  padding    : 24px 0;
  border-top : 1px solid var(--border-color2);
  background : var(--main-bg);
}

.footer-top {
  display         : flex;
  justify-content : space-between;

  &__right {
    width     : 334px;
    max-width : 100%;
    @media screen and (max-width : 991px) {
      margin : 32px auto 0 auto;
    }
    @media screen and (max-width : 991px) {
      width : 100%;
    }
  }

  @media screen and (max-width : 991px) {
    flex-wrap : wrap;
  }
}

.footer-form {
  display               : grid;
  grid-template-columns : 1fr 45px;
  gap                   : 12px;
  margin-top            : 24px;
  max-width             : 600px;
}

.button-icon {
  display : flex;
  width   : 24px;
}

.footer-text {
  h2 {
    font-weight : 600;
    font-size   : 14px;

    & + p {
      margin-top : 4px;
    }
  }

  p {
    color       : var(--text-color2);
    font-weight : 400;
    font-size   : 12px;
    line-height : 180%;
  }
}

.footer-bottom {
  position        : relative;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  margin-top      : 24px;
  @media screen and (max-width : 991px) {
    flex-wrap  : wrap;
    margin-top : 0;
  }
}

.footer-logo {
  position    : absolute;
  display     : flex;
  align-items : center;
  width       : 64px;
  bottom      : calc(100% + 32px);
  @media screen and (max-width : 991px) {
    position : relative;
    bottom   : 0;
  }
}

.footer-address {
  display       : flex;
  align-items   : center;
  gap           : 8px;
  margin-bottom : 6px;
  font-size     : 12px;

  &__icon {
    display   : flex;
    flex      : 0 0 10px;
    min-width : 10px;
  }

  @media screen and (max-width : 475px) {
    max-width       : 185px;
    justify-content : flex-end;
    text-align      : right;
    margin-left     : auto;
  }
}

.footer-extra {
  margin-top : 24px;
  font-size  : 12px;
}

.footer-bottom-group {
  @media screen and (max-width : 767px) {
    text-align : right;
  }
}

.footer-bottom-left {
  @media screen and (max-width : 991px) {
    width           : 100%;
    display         : flex;
    justify-content : space-between;
  }
}

.copyright {
  font-weight : 400;
  font-size   : 12px;
  line-height : 180%;
  color       : var(--text-color2);
}

.footer-nav {
  @media screen and (max-width : 991px) {
    width : 100%;
  }

  ul {
    display   : flex;
    flex-wrap : wrap;
    gap       : 32px;
    @media screen and (max-width : 991px) {
      width           : 100%;
      justify-content : space-between;
    }
    @media screen and (max-width : 575px) {
      justify-content : flex-start;
      flex-direction  : column;
      max-height      : 140px;
      gap             : 32px 0;
      > li {
        max-width : 120px;
      }
    }

    > li {
      > a,
      > button {
        line-height : 1.8;
        color       : var(--text-color-main);
        font-weight : 600;
        font-size   : 14px;
        transition  : all 0.2s;

        &:hover {
          color : var(--main-color);
        }
      }
    }
  }
}

.footer-social {
  display         : flex;
  align-items     : center;
  width           : 334px;
  max-width       : 100%;
  gap             : 24px;
  margin-top      : 16px;
  justify-content : space-between;
  @media screen and (max-width : 991px) {
    width  : 100%;
    order  : -3;
    margin : 32px auto;
  }

  &__item-icon {
    display : flex;
    width   : 21px;
  }

  &__item {
    display     : flex;
    align-items : center;
    gap         : 7px;
    font-weight : 600;
    font-size   : 12px;
    line-height : 180%;
    transition  : all 0.2s;
    color       : var(--text-color-main);

    &:hover {
      color : var(--main-color);

      .fill path {
        fill : var(--main-color);
      }
    }

    svg path {
      transition : 0.2s;
    }
  }

  @media screen and (max-width : 620px) {
    gap : 20px;
  }
}

.authorization-section {
  display     : flex;
  align-items : center;
  min-height  : unset;
  flex        : 1;
  padding     : 110px 0 124px 0;
  @media screen and (max-width : 767px) {
    padding : 60px 0 75px 0;
  }
}

.authorization-box {
  margin : 0 auto;
  @media screen and (max-width : 767px) {
    max-width : 100%;
  }
}

.auth-another-option {
  text-align  : center;
  padding-top : 24px;
  color       : #666;

  a {
    display : inline-block;
  }
}

.authorization-form,
.form {
  border-radius : 20px;
  background    : #FFF;
  border        : 1px solid var(--border-main);

  &__body {
    max-width    : 420px;
    margin-left  : auto;
    margin-right : auto;
  }
}

.authorization-form {
  &--height-100 {
    height : 100%;
  }
}

.form {
  &__body {
    margin-top : 24px;

    &--mt-12 {
      margin-top : 12px;
    }

    &--signup-setup {
      max-width : 516px;
    }
  }

  &__header {
    &--fixed {
      max-width    : 440px;
      margin-left  : auto;
      margin-right : auto;
    }
  }

  &__header-block {
    display         : flex;
    justify-content : space-between;
    max-width       : 420px;
    margin          : 0 auto;
  }

  &--signup-step2 {
    .form__body,
    .form__header-block {
      max-width : 516px;
    }
  }
}

.form-group {
  margin-top : 16px;

  &--more-mt {
    margin-top : 24px;
  }
}

.input-row {
  display               : grid;
  grid-template-columns : 1fr 1fr;
  gap                   : 0 16px;
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }
}

.form-title {
  font-weight : 600;
  font-size   : 34px;
  line-height : 1.5;

  &--center {
    text-align : center;
  }

  @media screen and (max-width : 1199px) {
    font-size : 30px;
  }
  @media screen and (max-width : 991px) {
    font-size : 28px;
  }
  @media screen and (max-width : 767px) {
    font-size : 24px;
  }
}

.form-subtitle {
  margin-top  : 12px;
  color       : var(--text-color2);
  text-align  : center;
  font-weight : 400;
  font-size   : 18px;
  line-height : 1.78;
  @media screen and (max-width : 767px) {
    font-size : 16px;
  }
}

.form-text {
  margin-top  : 12px;
  font-weight : 500;
  line-height : 1.65;
  font-size   : 14px;
  color       : var(--text-color2);

  &--center {
    text-align : center;
  }

  &--fw-600 {
    font-weight : 600;
  }

  &--fw-400 {
    font-weight : 400;
  }

  &--mt-0 {
    margin-top : 0;
  }

  &--type2 {
    color       : var(--text-color-main);
    font-size   : 16px;
    font-weight : 400;
  }

  &--color2 {
    color : var(--text-color-main);
  }

  &--fs-18 {
    font-size : 18px;
  }

  a {
    color : var(--main-color);
  }

  &--width-380 {
    max-width    : 380px;
    margin-left  : auto;
    margin-right : auto;
  }
}

.form-icon {
  display : flex;
  width   : 57px;
  margin  : 12px auto 0 auto;

  &--type2 {
    width : 47px;
  }
}

.form-footer {
  display         : flex;
  justify-content : center;
  margin-top      : 24px;
  gap             : 16px;

  &--more-mt {
    margin-top : 40px;
  }

  &--end {
    justify-content : flex-end;
  }

  &--settings {
    align-items     : center;
    justify-content : space-between;
    @media screen and (max-width : 575px) {
      flex-direction : column;
      gap            : 24px;
    }
  }

  &--start {
    justify-content : flex-start;
  }

  &--mob-column {
    @media screen and (max-width : 575px) {
      flex-direction : column;
      align-items    : center;
    }
  }
}

.form-container {
  max-width : 712px;
  margin    : 24px auto 0 auto;
}

.input-notification {
  display     : flex;
  align-items : center;
  gap         : 8px;
  margin-top  : 8px;
  font-weight : 500;
  font-size   : 12px;
  color       : var(--text-color2);

  &--error {
    color : #D1513A;
  }

  &__icon {
    display   : flex;
    width     : 23px;
    min-width : 23px;
  }

  &--center {
    justify-content : center;
  }

  & + .input-notification {
    margin-top : 16px;
  }

  &--mt-16 {
    margin-top : 16px;
  }

  &--mt-24 {
    margin-top : 24px;
  }
}

.link {
  display     : flex;
  color       : var(--main-color);
  font-weight : 500;
  font-size   : 14px;
  gap         : 12px;
  transition  : 0.2s;

  &--bigger {
    font-size : 16px;
  }

  &:hover {
    color : darken(#E41472, 10%);
  }
}

.save-password {
  display         : flex;
  justify-content : space-between;
  align-items     : center;

  &--center {
    justify-content : center;
  }

  &__info {
    display     : flex;
    align-items : center;
    gap         : 4px;
    color       : var(--text-color2);
  }

  &--type2 {
    @media screen and (max-width : 575px) {
      flex-direction : column;
      align-items    : flex-start;
      gap            : 12px;
    }
  }
}

.back-to {
  display         : flex;
  justify-content : center;
  gap             : 16px;
  margin-bottom   : 12px;
  font-weight     : 500;
  font-size       : 18px;
  color           : var(--main-color);

  &__arrow {
    display : flex;
    width   : 32px;
  }

  &--start {
    justify-content : flex-start;
  }

  &--type2 {
    color       : var(--text-color-main);
    font-size   : 14px;
    line-height : 1.8;
    font-weight : 400;

    .back-to__arrow {
      width : 15px;

      .stroke path {
        stroke : var(--text-color-main);
      }
    }
  }
}

.home-section {
  position        : relative;
  padding         : 227px 0 282px 0;
  background      : #696D73 url('../images/home-bg.webp') no-repeat 50% 0;
  background-size : cover;

  @media screen and (max-width : 991px) {
    padding : 120px 0;
  }
  @media screen and (max-width : 767px) {
    padding-top      : 40px;
    padding-bottom   : 92px;
    background: #fff url(../images/home-bg-mob.jpg) no-repeat 50% 0;
  }
}

.home {
  position  : relative;
  max-width : 767px;
  color     : #FFF;
  z-index   : 3;
  @media screen and (max-width : 991px) {
  }

  &__title {
    font-weight : 600;
    font-size   : 54px;
    line-height : 150%;
    text-shadow : 0 4px 4px rgba(0, 0, 0, 0.25);
    @media screen and (max-width : 991px) {
      font-size : 36px;
    }
    @media screen and (max-width : 767px) {
      font-size   : 30px;
      font-weight : 500;
      color       : var(--main-color);
      text-shadow : none;
    }
  }

  &__text {
    margin-top  : 32px;
    max-width   : 454px;
    font-weight : 400;
    font-size   : 20px;
    line-height : 180%;
    text-shadow : 0 4px 4px rgba(0, 0, 0, 0.25);
    @media screen and (max-width : 767px) {
      margin-top  : 16px;
      font-size   : 16px;
      color       : var(--text-color-main);
      text-shadow : none;
    }
  }

  &__btn {
    margin-top : 44px;
    @media screen and (max-width : 767px) {
      margin-top : 24px;
    }
  }
}

.br {
  display : block;

  &--on-mob {
    display : none;
    @media screen and (max-width : 767px) {
      display : block;
    }
  }

  &--mob-none {
    @media screen and (max-width : 767px) {
      display : none;
    }
  }
}

.home-line {
  position    : absolute;
  display     : flex;
  align-items : flex-end;
  bottom      : 0;
  left        : 0;
  width       : 100%;
  height      : 80px;
  background  : linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  z-index     : 2;
  @media screen and (max-width : 767px) {
    background : linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E9E9E9 100%);
  }

  &__scrolling-text {
    display         : flex;
    align-items     : center;
    justify-content : flex-start;
    margin-bottom   : 16px;
    overflow        : hidden;
    white-space     : nowrap;
    @media screen and (max-width : 767px) {
      //transform: translateX(-50%);

      //width: 1000px;
    }
  }
}

.home-info {
  flex-shrink : 0;
  display     : flex;
  align-items : center;
  min-width   : 100%;
  @media screen and (min-width : 1800px) {

  }
  @media screen and (max-width : 767px) {
    //margin-left: -calc(100% + 1px);
  }

  & + .home-info {
    margin-left : 0;
  }

  &__line {
    display        : inline-block;
    vertical-align : middle;
    margin         : 0 50px;
    height         : 32px;
    width          : 1px;
    background     : #4D4D56;
    @media screen and (max-width : 1024px) {
      margin : 0 32px;
    }
    @media screen and (max-width : 767px) {
      margin : 0 16px;
    }
  }

  p {
    display        : inline-block;
    vertical-align : middle;
    font-weight    : 600;
    font-size      : 16px;
    letter-spacing : 0.04em;
    text-transform : uppercase;
    color          : var(--text-color-white);
    @media screen and (max-width : 767px) {
      color : var(--text-color-main);
    }

    span {
      color : var(--main-color);
    }
  }

  &--first {
    animation : scrolling-left1 20s linear infinite;
  }

  &--second {
    animation : scrolling-left1 20s linear infinite;

  }
}

/* scrolling-left is continuous/repeatly text */

@keyframes scrolling-left1 {
  from {
    transform : translateX(0);
  }
  to {
    transform : translateX(-100%);
  }
}

@keyframes scrolling-left2 {
  0% {
    transform : translateX(0%);
  }
  100% {
    transform : translateX(-200%);
  }
}

.home-title {
  font-weight : 500;
  font-size   : 40px;
  line-height : 150%;
  color       : var(--main-color);

  a {
    position   : relative;
    color      : var(--main-color);
    transition : 0.2s linear;

    &:hover {
      color : darken(#E41472, 10%);

      &:after {
        background : darken(#E41472, 10%);
      }
    }

    &:after {
      content    : '';
      position   : absolute;
      left       : 0;
      bottom     : 7px;
      width      : 100%;
      height     : 2px;
      background : var(--main-color);
      transition : 0.2s linear;
    }
  }

  @media screen and (max-width : 1199px) {
    font-size : 36px;
  }
  @media screen and (max-width : 767px) {
    font-size : 30px;
  }
}

.home-subtitle {
  margin-top  : 8px;
  font-weight : 400;
  font-size   : 16px;
  line-height : 180%;
  color       : var(--main-color);
}

.info-text {
  margin-top  : 16px;
  font-weight : 400;
  font-size   : 16px;
  line-height : 180%;
  color       : var(--text-color-main);

  p {
    & + p {
      margin-top : 16px;
    }
  }
}

.what-we-do-section {
  padding : 60px 0;
  @media screen and (max-width : 991px) {
    padding : 40px 0;
  }
}

.what-we-do {
  display               : grid;
  grid-template-columns : 531px 1fr;
  gap                   : 99px;
  @media screen and (max-width : 1199px) {
    gap : 32px;
  }
  @media screen and (max-width : 1024px) {
    grid-template-columns : 400px 1fr;
  }
  @media screen and (max-width : 991px) {
    grid-template-columns : 1fr;
  }

  &__img {
    display     : flex;
    align-items : flex-start;

    img {
      border-radius : 15px;
    }

    @media screen and (max-width : 991px) {
      display : none;
    }
  }

  &__info-btn {
    margin-top : 32px;
  }
}

.landing-video {
  position    : relative;
  display     : flex;
  align-items : flex-start;

  video {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 15px;
  }

  &--pc {
    @media screen and (max-width : 767px) {
      display : none;
    }
  }

  &--mob {
    display : none;
    @media screen and (max-width : 767px) {
      margin-top : 40px;
      display    : flex;
      video {
        width        : 100%;
        height       : auto;
        aspect-ratio : 16 / 9;
      }
    }
  }
}

.play-btn {
  position        : absolute;
  left            : 50%;
  top             : 50%;
  transform       : translate(-50%, -50%);
  width           : 53px;
  height          : 53px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  background      : #FFF;
  border-radius   : 50%;
  border          : 2px solid var(--main-color);
  z-index         : 2;

  svg {
    position : relative;
    left     : 2px;
  }
}

.update-container {
  position : relative;
  padding  : 60px 0;
  @media screen and (max-width : 991px) {
    padding : 50px 0;
  }
}

.half-pixel-line {
  position        : relative;
  display         : flex;
  justify-content : center;
  height          : 1px;
  overflow        : hidden;

  &:after {
    content    : '';
    position   : absolute;
    left       : 50%;
    top        : 0;
    display    : block;
    height     : 0.01em;
    width      : 2556px;
    transform  : translateX(-50%) scale(0.5);
    background : #D9D9D9;
    @media screen and (max-width : 767px) {
      border-color : #EEE;
      border-style : solid;
      border-width : 0.01em;
      height       : auto;
      transform    : translateX(-50%);
    }
  }

  @media screen and (max-width : 767px) {
  }
}

.latest-update {
  margin-top : 32px;
}

.latest-update-slider {
  width : 100%;

  @media screen and (max-width : 1024px) {
    width       : calc(100% + 32px);
    margin-left : -16px;
    padding     : 0 32px;
  }

  .swiper-wrapper {
    width : 100%;
  }

  .market-card {
    width : 100%;
  }

  > .swiper-wrapper {
    transform : translate3d(0, 0, 0);

    @media screen and (min-width : 1024px) {
      gap : 20px;
      > .swiper-slide {
        max-width : 100%;
        flex      : 1;
      }
    }
  }

  .market-card {
    padding : 0;
  }

  .card-slider-box {
    .swiper-slide {
      width : 100% !important;
    }
  }

  .card-slider-item {
    height       : auto;
    aspect-ratio : 4 / 3;
  }
}

.step-section {
  padding : 60px 0;
  @media screen and (max-width : 991px) {
    padding : 50px 0;
  }
}

.step-list {
  position   : relative;
  width      : 100%;
  display    : flex;
  gap        : 20px;
  margin-top : 40px;
  @media screen and (max-width : 767px) {
    width          : calc(100% + 16px);
    overflow       : auto;
    padding-bottom : 10px;
  }
}

.step-item {
  width         : 100%;
  padding       : 40px;
  border-color  : #D5D5D5;
  border-style  : solid;
  border-width  : 0.01em;
  border-radius : 15px;

  &__title {
    margin-bottom : 8px;
    font-weight   : 500;
    font-size     : 24px;
    line-height   : 180%;
    color         : var(--main-color);
  }

  &__text {
    font-weight : 400;
    font-size   : 16px;
    line-height : 180%;
  }

  @media screen and (max-width : 991px) {
    padding   : 20px;
    min-width : 276px;
    &:last-child {
      margin-right : 16px;
    }
  }
}

.banner-section {
  position : relative;
  padding  : 60px 0;
  @media screen and (max-width : 991px) {
    padding : 80px 0;
    .container {
      padding : 0 8px;
    }
  }
}

.banner-img {
  position   : absolute;
  top        : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  object-fit : cover;
}

.banner-box {
  position : relative;
  display  : flex;
}

.banner {
  position      : relative;
  max-width     : 100%;
  background    : #FFF;
  border-radius : 15px;
  z-index       : 2;

  &--first {
    width       : 689px;
    margin-left : auto;
    padding     : 40px;
  }

  &--second {
    width   : 100%;
    padding : 64px;
    @media screen and (max-width : 767px) {
      padding : 40px;
    }
  }

  .home-subtitle {
    margin-top : 16px;
  }
}

.options-section {
  padding : 60px 0;
}

.options-container {
  max-width : 1080px;
  margin    : 0 auto;
}

.options-box {
  display               : grid;
  grid-template-columns : 1fr 1fr 1fr;
  gap                   : 20px;
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }
}

.home-option {
  position      : relative;
  padding       : 40px;
  border-color  : #D5D5D5;
  border-style  : solid;
  border-width  : 0.01em;
  border-radius : 15px;
  @media screen and (max-width : 767px) {
    font-size : 18px;
  }

  &__icon {
    display : flex;
    width   : 50px;
    padding : 5px;
  }

  &__title {
    margin      : 16px 0 8px 0;
    font-weight : 500;
    font-size   : 24px;
    color       : var(--main-color);
    line-height : 1.5;
    @media screen and (max-width : 991px) {
      font-size : 21px;
    }
  }

  &__text {
    font-weight : 400;
    font-size   : 16px;
    line-height : 180%;
  }
}

.partners-section {
  background    : #FAFAFA;
  border-top    : 1px solid #D9D9D9;
  border-bottom : 1px solid #D9D9D9;
  padding       : 60px 0;
}

.partners {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  margin-top      : 50px;
  gap             : 55px 30px;

  &__item {
    display  : flex;
    position : relative;
    @media screen and (max-width : 767px) {
      &:nth-child(1) {
        order     : -3;
        max-width : 74px;
        top       : 4px;
      }
      &:nth-child(2) {
        order     : -1;
        max-width : 115px;
      }
      &:nth-child(3) {
        order     : -2;
        max-width : 104px;
      }
      &:nth-child(4) {
        max-width : 95px;
      }
      &:nth-child(5) {
        max-width : 99px;
      }
    }
  }

  @media screen and (max-width : 767px) {
    max-width       : 400px;
    margin          : 40px auto 0 auto;
    justify-content : center;
    flex-wrap       : wrap;
    gap             : 45px 50px;
  }
}

.review-section {
  padding : 60px 0;
}

.review {
  height        : 100%;
  padding       : 40px 24px;
  border        : 1px solid var(--border-color-main);
  border-radius : 15px;
  text-align    : center;
  @media screen and (max-width : 767px) {
    padding : 40px 16px;
  }

  &__photo {
    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 96px;
    height          : 96px;
    margin          : 0 auto;

    img {
      width         : 100%;
      height        : 100%;
      border-radius : 50%;
      object-fit    : cover;
    }
  }

  &__text {
    margin-top : 16px;
    font-size  : 16px;
  }

  &__name {
    margin-top : 16px;
    font-size  : 20px;
    color      : var(--main-color);
  }

  &__role {
    margin-top : 4px;
    color      : var(--text-color2);
  }
}

.review-slider {
  margin-top     : 40px;
  padding-bottom : 60px;

  .swiper-wrapper {
    align-items : stretch;
  }

  .swiper-slide {
    height : auto;
  }

  .swiper-pagination {
    left            : 50%;
    transform       : translateX(-50%);
    bottom          : 0;
    display         : inline-flex;
    justify-content : center;
    width           : auto;
    background      : #FAFAFA;
    border-radius   : 6px;
    gap             : 6px;

    .swiper-pagination-bullet {
      width      : 12px;
      height     : 12px;
      margin     : 0;
      background : transparent;
      border     : 1px solid #D9D9D9;
    }

    .swiper-pagination-bullet-active {
      background : var(--main-color) !important;
      border     : var(--main-color);
    }
  }
}

.landing-faq-section {
  .faq-wrapper {
    padding-top    : 60px;
    padding-bottom : 70px;
    border-top     : 1px solid #D9D9D9;
    border-bottom  : 1px solid #D9D9D9;
  }
}

.get-started-section {
  padding : 80px 0;
  @media screen and (max-width : 1199px) {
    padding : 70px 0;
  }
  @media screen and (max-width : 767px) {
    padding : 60px 0;
  }
}

.get-started {
  width         : 924px;
  max-width     : 100%;
  margin        : 0 auto;
  background    : #FAFAFA;
  border        : 1px solid #E6E6E6;
  border-radius : 15px;
  padding       : 44px;
  text-align    : center;

  &__title {
    font-size   : 32px;
    color       : var(--main-color);
    font-weight : 500;
    @media screen and (max-width : 1199px) {
      font-size : 28px;
    }
    @media screen and (max-width : 767px) {
      font-size : 24px;
    }
  }

  &__text {
    margin-top  : 8px;
    font-weight : 500;
    font-size   : 20px;
    line-height : 180%;
    @media screen and (max-width : 1199px) {
      font-size : 18px;
    }
    @media screen and (max-width : 767px) {
      font-size : 16px;
    }
  }

  &__btn {
    margin-top : 24px;
  }
}

.market-section {
  padding : 40px 0 60px 0;
  @media screen and (max-width : 767px) {
    padding : 32px 0;
  }
}

.market-filters {
  display         : flex;
  justify-content : flex-start;
  align-items     : center;
  gap             : 24px 16px;
  @media screen and (max-width : 1024px) {
    display               : grid;
    grid-template-columns : repeat(4, 1fr);
    gap                   : 16px 8px;
  }
  @media screen and (max-width : 767px) {
    display : flex;
    .select {
      &__current {
        gap     : 4px;
        padding : 0 8px;
      }
    }
  }
}

.filter-group {
  display : flex;
  gap     : 24px 16px;
  @media screen and (max-width : 767px) {
    width          : 100%;
    flex-direction : column;
  }
}

.filter {
  width : 219px;

  &--big {
    width : 292px;
  }

  @media screen and (max-width : 1024px) {
    width : 100%;
    .select__current {
      font-size : 14px;
    }
    .select__drop {
      min-width : 200px;
    }
  }
  @media screen and (max-width : 575px) {
    .select__current {
      font-size : 0;
    }
  }

  &:nth-child(3) {
    @media screen and (max-width : 767px) {
      .select__drop {
        left  : unset;
        right : 0;
      }
    }
  }

  &:last-child {
    margin-left : auto;
    @media screen and (max-width : 767px) {
      .select__drop {
        min-width : 160px;
        left      : unset;
        right     : 0;
      }
    }
  }
}

.market-layout {
  display   : flex;
  flex-wrap : wrap;
  gap       : 30px 0;
  margin    : 40px -11px 0 -11px;
  @media screen and (max-width : 767px) {
    margin-top : 24px;
  }
}

.market-card {
  width   : 25%;
  padding : 0 11px;
  @media screen and (max-width : 1199px) {
    width : 33.3%;
  }
  @media screen and (max-width : 820px) {
    width : 50%;
  }
  @media screen and (max-width : 500px) {
    width : 100%;
  }

  &__top {
    position : relative;
  }

  &__info {
    margin-top : 16px;
  }

  .object-label {
    position : absolute;
    top      : 24px;
    left     : 24px;
    z-index  : 3;

    &__dot {
      display : none;
    }
  }

  .add-to-fav {
    position : absolute;
    top      : 24px;
    right    : 23px;
  }

  &--my-properties {
    width : 100%;
  }

  .card-slider-item {
    height       : auto;
    aspect-ratio : 4 / 3;
  }
}

.add-to-fav,
.object-action {
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 30px;
  height          : 30px;
  background      : rgba(37, 37, 46, 0.15);
  backdrop-filter : blur(10px);
  border-radius   : 8px;
  z-index         : 3;

  svg {
    width : 16px;
  }

  .stroke path {
    stroke     : #FFF;
    transition : 0.2s;
  }

  &--type2 {
    svg {
      width : 18px;
    }

    background : var(--button-bg2);

    .stroke path {
      stroke     : var(--text-color-main);
      transition : 0.2s;
    }
  }

  &:hover,
  &.active {
    .stroke path {
      stroke : var(--main-color);
    }
  }

  &--at-card {
    position : absolute;
    top      : 24px;
    right    : 23px;
  }
}

.card-name {
  font-weight : 700;
  font-size   : 14px;
  line-height : 180%;
  color       : var(--text-color-main);

  &--bigger {
    font-size : 18px;
  }
}

.card-address {
  font-weight : 400;
  font-size   : 14px;
  line-height : 180%;
  color       : var(--text-color2);

  &--bigger {
    font-size : 18px;
  }
}

.market-card-options {
  margin-top : 4px;
}

.card-options {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  font-weight     : 500;
  line-height     : 180%;

  &__name {
    font-weight : 500;
  }

  &__value {
    text-align  : right;
    font-weight : 600;

    &--extra {
      color       : var(--main-color);
      font-weight : 500;
    }
  }

  &--type2 {
    & + .card-options--type2 {
      margin-top : 4px;
    }
  }
}

.card-slider-item {
  display       : flex;
  height        : 276px;
  border-radius : 15px;

  img {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 15px;

    &.swiper-lazy {
      background : rgba(255, 255, 255, .5);
    }
  }

  @media screen and (max-width : 500px) {
    height : 313px;
  }
}

.card-slider-box {
  position : relative;
}

.swiper-button-next,
.swiper-button-prev {
  position        : absolute;
  top             : 50%;
  transform       : translateY(-50%);
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 30px;
  height          : 30px;
  margin          : 0;
  background      : rgba(255, 255, 255, 0.8) !important;
  border-radius   : 50%;
  z-index         : 3;
  transition      : 0.2s;

  svg {
    width : 10px;
  }

  &:hover {
    background : rgba(255, 255, 255, 0.95) !important;
  }

  &.swiper-button-disabled {
    opacity : 0;
  }
}

.swiper-button-next {
  right : 23px;
}

.swiper-button-prev {
  left : 23px;

  svg {
    transform : rotate(180deg);
  }
}

.object-label {
  display       : inline-flex;
  align-items   : center;
  height        : 30px;
  gap           : 10px;
  border-radius : 15px;
  padding       : 0 16px;
  font-weight   : 600;
  font-size     : 14px;

  &__dot {
    display       : none;
    width         : 10px;
    height        : 10px;
    border-radius : 50%;
  }

  &--live {
    background : #57EF82;
    color      : #FFF;

    .object-label__dot {
      background : #FFF;
    }
  }

  &--live-old {
    background : #31BDAA;
    color      : #FFF;

    .object-label__dot {
      background : #FFF;
    }
  }

  &--soon {
    background : #C2E812;
    color      : #FFF;

    .object-label__dot {
      background : var(--text-color-main);
    }
  }

  &--sold-out {
    background : #706D6D;
    color      : #FFF;

    .object-label__dot {
      background : #FFF;
    }
  }

  &--at-card {
    position : absolute;
    top      : 24px;
    left     : 23px;
    z-index  : 3;
  }
}

.swiper-pagination-bullet {
  width      : 10px;
  height     : 10px;
  background : #C6C6C6;
  opacity    : 1 !important;
}

.swiper-pagination-bullet-active {
  background : #FFF !important;
}

.real-estate-section {
  padding : 28px 0 60px 0;
  @media screen and (max-width : 767px) {
    padding : 17px 0 100px 0;
  }
}

.realty-wrapper {
  max-width : 1100px;
  margin    : 0 auto;
}

.back-btn-block {
  margin-bottom : 16px;
}

.back-btn {
  display     : flex;
  align-items : center;
  font-size   : 14px;
  line-height : 1.8;
  transition  : all 0.2s;

  &__arrow {
    display      : flex;
    flex         : 0 0 15px;
    min-width    : 15px;
    margin-right : 12px;
  }

  &:hover {
    color : var(--main-color);
  }
}

.realty-header {
  display         : flex;
  justify-content : space-between;
  gap             : 16px;
  @media screen and (max-width : 767px) {
    flex-direction : column;
    margin-top     : 18px;
  }
}

.realty-name {
  font-weight : 700;
  font-size   : 24px;
  line-height : 180%;
  @media screen and (max-width : 991px) {
    font-size : 22px;
  }
  @media screen and (max-width : 767px) {
    font-size : 20px;
  }
}

.realty-address {
  display     : flex;
  align-items : center;
  gap         : 12px;
  color       : var(--text-color-main);
  font-weight : 400;
  font-size   : 18px;
  line-height : 180%;
  @media screen and (max-width : 767px) {
    font-size : 16px;
  }

  &__icon {
    display    : flex;
    min-width  : 16px;
    max-height : 20px;
  }
}

.realty-actions {
  display         : flex;
  align-items     : center;
  justify-content : flex-end;
  gap             : 16px;
  @media screen and (max-width : 767px) {
    justify-content : flex-start;
  }
}

.realty-date {
  display         : flex;
  justify-content : flex-end;
  gap             : 10px;
  text-align      : right;
  margin-top      : 16px;
  font-size       : 16px;
  line-height     : 180%;
  @media screen and (max-width : 767px) {
    justify-content : flex-start;
  }

  span {
    font-weight : 600;
  }
}

.show-mob {
  display : none;
  @media screen and (max-width : 767px) {
    display : block;
  }
}

.hide-mob {
  display : block;
  @media screen and (max-width : 767px) {
    display : none;
  }
}

.info-title {
  font-weight : 700;
  font-size   : 20px;
  line-height : 180%;

  &--fw-600 {
    font-weight : 600;
  }
}

.realty-block {
  margin-top : 40px;

  &__content {
    margin-top : 24px;
  }

  &__header {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    flex-wrap       : wrap;
    gap             : 16px;

    &--type2 {
      border-bottom  : 2px solid var(--main-color);
      padding-bottom : 26px;
    }

    .button--type4 {
      border-width : 2px;
    }

    @media screen and (max-width : 767px) {
      flex-direction : column;
      align-items    : flex-start;
      .switcher {
        width : 100%;
      }
    }
  }

  &--type2 {
    border-top  : 1px solid var(--border-color2);
    padding-top : 40px;
  }
}

.realty-info-box {
  display               : grid;
  grid-template-columns : 1fr 1fr;
  gap                   : 16px;
  @media screen and (max-width : 991px) {
    grid-template-columns : 1fr 1fr;
  }
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }

  &__item {
    padding       : 24px 16px;
    border        : 1px solid #CFCFCF;
    border-radius : 15px;

    .realty-info + .realty-info {
      margin-top : 20px;
    }

    &--third,
    &:nth-child(2) {
      .realty-info + .realty-info {
        //margin-top: 20px;
      }
    }

    &:first-child {
      @media screen and (max-width : 767px) {
        .realty-info {
          flex-wrap : wrap;

          &__value {
            width        : 100%;
            padding-left : 36px;
          }
        }
      }
    }
  }
}

.realty-info {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  gap             : 0 12px;
  line-height     : 180%;

  &__name {
    display     : flex;
    align-items : center;
    gap         : 12px;
    //max-width: 180px;
    font-weight : 500;
    @media screen and (max-width : 767px) {
      max-width : 100%;
    }

    &--short {
      max-width : 150px;
    }
  }

  &__name-icon {
    display : flex;
    flex    : 0 0 24px;
  }

  &__value {
    font-weight : 600;
    font-size   : 18px;
  }

  & + .realty-info {
    margin-top : 8px;
  }
}

.realty-overview {
  &__text {
    max-width   : 900px;
    font-weight : 400;
    font-size   : 16px;
    line-height : 180%;

    p {
      margin-bottom : 16px;
    }

    h4 {
      margin-bottom : 8px;
      font-weight   : 600;
    }
  }

  &__text-hidden {
    display : none;
  }

  &__more {
    margin-top : 16px;
  }

  &.active {
    .realty-overview__text-hidden {
      display : block;
    }
  }
}

.realty-map {
  margin-top : 40px;
  max-width  : 100%;
  height     : 397px;

  img,
  iframe {
    width      : 100%;
    height     : 100%;
    object-fit : cover;
  }

  @media screen and (max-width : 767px) {
    width       : calc(100% + 30px);
    margin-left : -15px;
    max-width   : unset;
  }
}

.realty-invest {
  position    : relative;
  //top: 2px;
  margin-top  : -50px;
  width       : 303px;
  margin-left : auto;

  &__btn {
    display         : flex;
    justify-content : center;
    @media screen and (max-width : 767px) {
      .button {
        max-width : 160px;
      }
    }

    &--type2 {
      @media screen and (max-width : 767px) {
        margin : 0 auto;
        width  : 100%;
        .button {
          max-width : unset;
          width     : 100%;
        }
      }
    }
  }

  &--with-progress {
    width : 340px;
    @media screen and (max-width : 767px) {
      justify-content : center !important;
    }
  }

  @media screen and (max-width : 767px) {
    position        : fixed;
    bottom          : 0;
    left            : 0;
    top             : unset;
    display         : flex;
    height          : 81px;
    align-items     : center;
    justify-content : space-between;
    width           : 100%;
    background      : #FFF;
    padding         : 16px;
    box-shadow      : 0px -5px 10px rgba(0, 0, 0, 0.05);
    z-index         : 30;
    .realty-progress {
      display : none;
    }
  }

  &--type2 {
    @media screen and (max-width : 767px) {
      justify-content : center;
    }
  }
}

.realty-progress {
  margin-bottom : 16px;
  background    : #F3F3F3;
  border-radius : 15px;
  padding       : 24px;

  &__details {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    margin-bottom   : 12px;
    font-size       : 16px;

    p {
      span {
        font-weight : 600;
      }
    }
  }

  &__line {
    position      : relative;
    background    : #CFCFCF;
    border-radius : 10px;
    height        : 6px;
  }

  &__item {
    position      : absolute;
    left          : 0;
    top           : 50%;
    transform     : translateY(-50%);
    height        : 6px;
    border-radius : 10px;
    background    : var(--main-color);
  }

  @media screen and (max-width : 767px) {
  }

  &--update {
    margin-top    : 16px;
    margin-bottom : 0;
    padding       : 0;
    background    : none;

    .realty-progress__details {
      margin    : 12px 0 0 0;
      font-size : 14px;
    }

    .realty-progress__line {
      height        : 4px;
      width         : 86px;
      margin-left   : auto;
      background    : #CFCFCF;
      border-radius : 10px;
    }

    .realty-progress__item {
      background    : #1A1A1A;
      height        : 4px;
      border-radius : 10px;
    }
  }
}

.token-to-invest {
  display         : flex;
  align-items     : center;
  justify-content : center;
  gap             : 21px;
  padding         : 20px 55px;
  background      : var(--block-bg3);
  border-radius   : 15px;
  line-height     : 180%;

  & + .realty-invest__btn {
    margin-top : 21px;
    @media screen and (max-width : 767px) {
      margin-top : 0;
    }
  }

  @media screen and (max-width : 991px) {
    padding : 20px;
  }

  &__icon {
    display : flex;
    width   : 45px;
    @media screen and (max-width : 767px) {
      display : none;
    }
  }

  &__value {
    margin-top  : 4px;
    font-weight : 600;
    font-size   : 20px;
  }

  @media screen and (max-width : 767px) {
    padding    : 0;
    background : none;
  }

  &__title {
    @media screen and (max-width : 767px) {
      font-size : 11px;
    }
  }

  &__value {
    @media screen and (max-width : 767px) {
      margin-top : 0;
      font-size  : 14px;
    }
  }

  &--pc-view-only {
    @media screen and (max-width : 767px) {
      background : var(--block-bg3);
      .token-to-invest__icon {
        display : flex;
      }
      .token-to-invest__title {
        font-size : 14px;
      }
      .token-to-invest__value {
        font-size : 20px;
      }
    }
  }
}

.realty-features-box {
  display : flex;
}

.features-list {
  display     : flex;
  align-items : flex-start;
  flex-wrap   : wrap;
  gap         : 16px 31px;

  &__group {
    display               : grid;
    grid-template-columns : 1fr 1.1fr;
    align-items           : center;
    max-width             : 475px;
    gap                   : 16px 40px;
    @media screen and (max-width : 575px) {
      max-width             : 100%;
      grid-template-columns : 1fr;
      .realty-features:nth-child(1) {
        order : 1;
      }
      .realty-features:nth-child(2) {
        order : 4;
      }
      .realty-features:nth-child(3) {
        order : 2;
      }
      .realty-features:nth-child(4) {
        order : 5;
      }
      .realty-features:nth-child(5) {
        order : 3;
      }
      .realty-features:nth-child(6) {
        order : 6;
      }
    }

    &--second {
      .realty-features__info {
        @media screen and (max-width : 767px) {
          gap : 4px 16px;
        }
      }
    }
  }
}

.realty-features {
  display     : flex;
  align-items : flex-start;
  gap         : 16px;

  &__icon {
    display : flex;
    flex    : 0 0 26px;
  }

  &__info {
    display     : flex;
    align-items : center;
    flex-wrap   : wrap;
    gap         : 8px 16px;
  }

  &__name {
    &--mob {
      display : none;
    }
  }

  &__value {
    font-weight : 600;
    font-size   : 16px;

    &--full {
      width : 100%;
    }

    &--extra {
      color : var(--main-color);
    }
  }

  & + .realty-features {
  }
}

.realty-doc-list {
  display        : flex;
  flex-direction : column;
  gap            : 26px;
}

.realty-doc {
  display     : flex;
  align-items : center;
  gap         : 12px;

  &__icon {
    display    : flex;
    min-width  : 16px;
    max-height : 20px;
  }

  a {
    font-weight     : 500;
    font-size       : 16px;
    line-height     : 180%;
    text-decoration : underline;
    color           : #3D58DB;

    &:hover {
      text-decoration : none;
    }
  }
}

.offering-details-item {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  gap             : 8px 16px;
  //padding: 16px 0;
  //border-bottom: 1px solid var(--border-color-main);
  &:first-child {
    padding-top : 0;
  }

  &__name {
    font-size : 16px;
  }

  &__value {
    font-size   : 18px;
    font-weight : 600;
  }

  & + .offering-details-item {
    margin-top : 16px;
  }

  @media screen and (max-width : 767px) {
    flex-direction : column;
    align-items    : flex-start;
  }
}

.switcher-wrapper {
  margin-bottom   : 40px;
  display         : flex;
  justify-content : space-between;
  gap             : 16px;

  .switcher--4items {
    max-width : 733px;
  }

  @media screen and (max-width : 1315px) {
    flex-wrap : wrap;
  }
  @media screen and (max-width : 635px) {
    .switcher--mob-column,
    .switcher:first-child {
      flex-direction : column;
      max-width      : 100%;

      .switcher__item {
        min-height : 50px;
      }
    }
  }
}

.switcher {
  display       : flex;
  width         : 100%;
  background    : var(--block-bg3);
  border-radius : 14px;
  padding       : 8px;

  &--fixed-size,
  &--smaller {
    width     : 368px;
    max-width : 100%;
  }

  &__item {
    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 100%;
    height          : 48px;
    padding         : 0 5px;
    border-radius   : 7px;
    font-weight     : 500;
    font-size       : 16px;
    line-height     : 1.25;
    letter-spacing  : -0.5px;
    color           : var(--text-color-main);

    &.active {
      background  : #FFF;
      box-shadow  : 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
      color       : var(--main-color);
      font-weight : 600;
    }
  }
}

.realty-photos {
  margin-top : 14px;

  .card-slider-item {
    @media screen and (max-width : 500px) {
      height : 343px;
    }
  }
}

.gallery-section {
  padding : 40px 0;
  @media screen and (max-width : 767px) {
    padding-top : 0;
  }
}

.gallery-container {
  max-width : 932px;
  margin    : 0 auto;
  @media screen and (max-width : 767px) {
    width       : calc(100% + 32px);
    margin-left : -16px;
    .realty-photo {
      img {
        border-radius : 0;
      }

      &--height-type3 {
        height : 249px;
      }

      &--height-type4 {
        height : 123px;
      }
    }
    .realty-photos-gallery {
      margin : 0 -1.5px;
      gap    : 3px 0;
    }
    .realty-photo-block {
      padding : 0 1.5px;
      gap     : 3px 0;
    }
  }
}

.realty-photos-gallery {
  display   : flex;
  flex-wrap : wrap;
  margin    : 0 -8px;
  gap       : 16px 0;
  @media screen and (max-width : 767px) {
    display : none;
  }

  &--always-display {
    display : flex;
    @media screen and (max-width : 767px) {
      display : flex;
    }
  }
}

.realty-photo-block {
  position  : relative;
  display   : flex;
  flex-wrap : wrap;
  gap       : 16px;
  padding   : 0 8px;
  width     : 33.3%;

  &--half {
    width : 50%;
  }

  &--two-third {
    width : 66.6%;
  }

  &--two-row {
    .realty-photo {
      width : calc(50% - 8px);
    }
  }
}

.realty-photo {
  display : flex;
  height  : 244px;
  width   : 100%;
  @media screen and (max-width : 991px) {
    height : 192px;
  }

  img {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 15px;
  }

  &--height-type2 {
    height : 503px;
    @media screen and (max-width : 991px) {
      height : 400px;
    }
  }

  &--height-type3 {
    height : 616px;
  }

  &--height-type4 {
    height : 300px;
  }
}

.slider-header {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  height          : 46px;
  width           : 1310px;
  max-width       : 100%;
  margin          : 60px auto 0 auto;

  &__close {
    display         : flex;
    align-items     : center;
    justify-content : center;
    gap             : 8px;
    width           : 100px;
    height          : 45px;
    background      : rgba(37, 37, 46, 0.5);
    border-radius   : 14px;
    font-weight     : 500;
    font-size       : 16px;
    color           : #FFF;

    svg {
      width : 16px;
    }
  }

  &__count {
    font-size : 20px;
    color     : #FFF;
    @media screen and (max-width : 1280px) {
      font-size : 16px;
    }
  }

  .realty-actions {
    gap : 0 40px;
  }

  .object-action {
    width           : 32px;
    padding         : 0;
    background      : none;
    backdrop-filter : none;

    svg {
      width : 100%;
    }

    .fill path {
      fill : #FFF;
    }

    .stroke path {
      stroke : #FFF;
    }
  }

  @media screen and (max-width : 1280px) {
    position   : absolute;
    top        : 0;
    left       : 0;
    margin-top : 30px;
    padding    : 0 16px;
  }
}

.photo-slider-box {
  position        : relative;
  display         : flex;
  justify-content : center;
  align-items     : center;
  gap             : 0 80px;
  width           : 100%;
  margin-top      : 40px;
  @media screen and (max-width : 1280px) {
    margin-top : 100px;
    gap        : 0 40px;
  }
  @media screen and (max-width : 767px) {
    width : calc(100% + 20px);
    .slider-arrow {
      display : none;
    }
  }
}

.slider-arrow {
  display         : flex;
  justify-content : center;
  align-items     : center;
  height          : 60px;
  flex            : 0 0 60px;
  border-radius   : 50%;
  background      : rgba(37, 37, 46, 0.5);
  @media screen and (max-width : 1280px) {
    flex   : 0 0 40px;
    height : 40px;
    svg {
      width : 12px;
    }
  }

  svg {
    width : 15px;
  }

  &:disabled {
    background : rgba(37, 37, 46, 0.3);

    svg {
      opacity : 0.2;
    }
  }

  &--prev {
    svg {
      margin-left : -4px;
      transform   : rotate(180deg);
    }
  }

  &--next {
    svg {
      margin-right : -4px;
    }
  }
}

.photo-slide {
  display         : flex;
  width           : 100%;
  max-width       : 1000px;
  margin          : 0 auto;
  justify-content : center;

  img {
    width         : auto;
    height        : auto;
    max-width     : 100%;
    border-radius : 15px;
    @media screen and (max-width : 767px) {
      border-radius : 0;
    }
  }
}

.all-photos {
  position : absolute;
  bottom   : 24px;
  left     : 32px;
  width    : 194px;
  display  : flex;
  z-index  : 5;

  &__btn {
    background : #FFF;
    border     : 1px solid var(--border-color-main);
    color      : var(--text-color-main);

    &:hover {
      background : #FFF;
      color      : var(--main-color);

      .fill path {
        fill : var(--main-color);
      }
    }
  }
}

.main-section {
  padding : 40px 0 60px 0;
  @media screen and (max-width : 767px) {
    padding-top : 24px;
  }

  &--wallet-convert {
    padding : 40px 0 126px 0;
    @media screen and (max-width : 767px) {
      padding : 24px 0 60px 0;
    }
  }

  &--2fa-page {
    @media screen and (max-width : 767px) {
      padding : 24px 0 90px 0;
    }
  }

  &--wallet-history {
    padding-bottom : 80px;
    @media screen and (max-width : 767px) {
      padding-bottom : 60px;
    }
  }

  &--faq {
    padding-bottom : 178px;
    @media screen and (max-width : 991px) {
      padding-bottom : 100px;
    }
  }

  &--terms {
    padding-bottom : 100px;
    @media screen and (max-width : 991px) {
    }
  }
}

.sell-form {
  max-width : 664px;
  margin    : 38px auto 0 auto;

  .form-group {
    & + .form-group {
      margin-top : 40px;
    }
  }
}

.form-box {
  margin-top : 40px;
}

.form-group {
  position : relative;

  & + .form-group {
    //margin-top: 40px;
  }
}

.block-step {
  position    : absolute;
  display     : flex;
  align-items : center;
  top         : 5px;
  left        : -100px;
  font-weight : 700;
  font-size   : 18px;
  gap         : 16px;

  &__icon {
    display : flex;
    width   : 24px;
  }

  @media screen and (max-width : 1024px) {
    position      : relative;
    top           : 0;
    left          : 0;
    margin-bottom : 16px;
  }
}

.input-name {
  margin-bottom : 8px;
  font-weight   : 500;
  font-size     : 16px;
  line-height   : 180%;

  &--big {
    font-size : 18px;
  }

  &--color2 {
    color : var(--text-color2);
  }
}

.flag-icon {
  display     : flex;
  align-items : center;
  flex        : 0 0 24px;

  img {
    width : 100%;
  }
}

.upload-gallery {
  display               : grid;
  grid-template-columns : repeat(3, 1fr);
  gap                   : 30px;
  @media screen and (max-width : 767px) {
    gap : 15px;
  }
  @media screen and (max-width : 475px) {
    grid-template-columns : repeat(2, 1fr);
  }
}

.uploaded-item {
  //display: flex;
  overflow : hidden;

  .upload-file-box {
    @media screen and (max-width : 767px) {
      height : 164px;
    }
  }
}

.uploaded-item-text {
  margin-top  : 4px;
  font-weight : 500;
  font-size   : 12px;
  line-height : 22px;

  &--red {
    color : #D1513A;
  }
}

.uploaded-photo {
  position : relative;
  display  : flex;
  height   : 202px;
  width    : 100%;
  @media screen and (max-width : 767px) {
    height : 164px;
  }

  img {
    width         : 100%;
    height        : 100%;
    object-fit    : cover;
    border-radius : 8px;
  }
}

.upload-action {
  position       : absolute;
  top            : 50%;
  left           : 50%;
  transform      : translate(-50%, -50%);
  min-width      : 100px;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  text-align     : center;
  gap            : 8px;
  font-weight    : 500;
  font-size      : 16px;
  color          : #FFF;
  @media screen and (max-width : 767px) {
    font-size : 14px;
  }

  &__icon {
    width : 24px;
  }

  &__hide-text {
    @media screen and (max-width : 767px) {
      display : none;
    }
  }
}

.item-loader {
  margin-top : 4px;

  &__size-text {
    font-weight : 400;
    font-size   : 12px;
    line-height : 22px;
    color       : var(--text-color-main);
  }

  &__upload-text {
    margin-bottom : 10px;
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 22px;
    color         : #E41472;
  }

  &__item {
    position      : relative;
    width         : 100%;
    height        : 4px;
    background    : #FCE1ED;
    border-radius : 5px;

    span {
      position      : absolute;
      top           : 0;
      left          : 0;
      display       : block;
      height        : 100%;
      background    : #E41472;
      border-radius : 5px;
    }
  }
}

.popup-alert {
  max-width  : 383px;
  margin     : 0 auto;
  text-align : center;

  .success-icon {
    margin : 0 auto;
  }

  &--type2 {
    padding : 20px 0;
  }
}

.popup-title {
  font-size   : 24px;
  font-weight : 600;

  &--center {
    text-align : center;
  }

  &--big {
    font-size : 34px;
    @media screen and (max-width : 991px) {
      font-size : 30px;
    }
    @media screen and (max-width : 991px) {
      font-size : 22px;
    }
  }
}

.success-icon {
  display         : flex;
  justify-content : center;
  max-width       : 24px;

  & + .popup-title {
    margin-top : 16px;

    &--mt-24 {
      margin-top : 24px;
    }
  }
}

.profile {
  display     : flex;
  align-items : flex-start;
  min-height  : 50vh;
  margin-top  : 30px;
  gap         : 40px 60px;

  &__bar {
    flex        : 0 0 200px;
    padding-top : 10px;
    @media screen and (max-width : 991px) {
      width       : 100%;
      flex        : 0 0 100%;
      padding-top : 0;
    }
  }

  &__content {
    flex        : 1 1 auto;
    padding-top : 10px;
    @media screen and (max-width : 991px) {
      width   : 100%;
      flex    : 0 0 100%;
      padding : 0;
    }
  }

  @media screen and (max-width : 1199px) {
    gap : 40px;
  }
  @media screen and (max-width : 991px) {
    flex-wrap  : wrap;
    margin-top : 0;
  }
  @media screen and (max-width : 767px) {
    min-height : unset;
  }

  &--type2 {
    @media screen and (max-width : 991px) {
      //gap: 16px 40px;
    }
  }
}

.settings-form {
  max-width : 620px;
  margin    : 0 auto;
  @media screen and (max-width : 991px) {
    max-width : 100%;
  }
}

.profile-header {
  display         : flex;
  align-items     : flex-start;
  justify-content : space-between;
  flex-wrap       : wrap;
  margin-bottom   : 40px;
  gap             : 16px;
  @media screen and (max-width : 767px) {
    flex-direction : column;
    align-items    : flex-start !important;
    .switcher {
      order         : -2;
      margin-top    : -24px;
      margin-bottom : 24px;
      width         : 100%;
    }
  }

  .token-to-invest {
    justify-content : flex-start;
    width           : 350px;
    max-width       : 100%;
    margin-top      : 16px;
    padding         : 24px;

    &__icon {
      width : 60px;
    }

    @media screen and (max-width : 767px) {
      width : 100%;
    }
  }

  &__group {
    max-width : 592px;
    @media screen and (max-width : 767px) {
      max-width : 100%;
      width     : 100%;
    }
  }

  &--type2 {
    margin-bottom : 24px;
    @media screen and (max-width : 767px) {
    }
  }

  &--align-end {
    align-items : flex-end;
  }

  .block-subtitle {
    margin-top : 8px;
    text-align : left;
  }
}

.table {
  &--realty-trade {
    .tr {
      grid-template-columns : 1.2fr 1.5fr 1.5fr 1fr;
      @media screen and (max-width : 767px) {
        & + .tr {
          margin-top : 24px;
        }
      }
    }

    @media screen and (max-width : 767px) {
      border  : none;
      padding : 0;
      .td {
        padding : 0;
      }
    }
  }

  &--second-type {
    padding : 0;
    border  : none;

    .table-header {
      border-bottom  : 2px solid var(--main-color);
      padding-bottom : 8px;
    }

    .td {
      padding : 0;

      &:nth-child(2) {
        @media screen and (max-width : 767px) {
          flex-wrap : wrap;
        }
      }
    }

    .tr {
      align-items : center;

      & + .tr {
        margin-top : 30px;
        @media screen and (max-width : 767px) {
          margin-top : 40px;
        }
      }

      @media screen and (max-width : 767px) {
        padding-top : 24px;
        border-top  : 2px solid var(--main-color) !important;
      }
    }
  }

  &--notification {
    .table-header {
      @media screen and (max-width : 767px) {
        display        : block;
        border         : none !important;
        padding-bottom : 40px;
        .tr {
          border      : none !important;
          padding-top : 0;
        }
        .td {
          &:nth-child(1),
          &:nth-child(2) {
            display : none;
          }

          &--right {
            text-align : left;
          }
        }
      }
    }

    .tr {
      grid-template-columns : 1.1fr 2fr 1fr;

      & + .tr {
        margin-top : 30px;
        @media screen and (max-width : 767px) {
          margin-top : 40px;
        }
      }

      @media screen and (max-width : 767px) {
        padding-top : 24px;
        border-top  : 2px solid var(--main-color) !important;
      }
    }

    .tr {
      align-items : baseline;

      &.unread {
        color : var(--main-color);
      }
    }
  }

  &--wallet-type {
    .tr {
      align-items : center;

      & + .tr {
        margin-top : 24px;
        @media screen and (max-width : 767px) {
          margin-top : 40px;
        }
      }
    }

    @media screen and (max-width : 767px) {
      .table-body .td + .td {
        margin-top : 15px;
      }
      .td-actions {
        margin-top : 15px;
      }
    }
  }

  &--wallet-type2 {
    .tr {
      align-items : center;

      & + .tr {
        margin-top : 30px;
        @media screen and (max-width : 767px) {
          margin-top : 40px;
        }
      }
    }

    @media screen and (max-width : 767px) {
      .td-actions {
        margin-top : 15px;
      }
    }
  }

  &--wallet-balance {
    .tr {
      grid-template-columns : 1fr 1fr 1fr 1fr 3.5fr;
    }
  }

  &--transactions-history {
    .tr {
      grid-template-columns : 0.8fr 0.9fr 1.3fr 0.9fr 0.9fr 1.2fr;
    }

    .table-header {
      @media screen and (max-width : 767px) {
        .td {
          display : none;

          &:nth-child(1),
          &:nth-child(2) {
            display : block;
          }

          &--right {
            text-align : left;
          }
        }
      }
    }
  }

  &--transactions-history,
  &--trade-history {
    .table-header {
      @media screen and (max-width : 767px) {
        display        : block;
        border         : none !important;
        padding-bottom : 40px;
        .tr {
          border      : none !important;
          padding-top : 0;
          gap         : 24px;
        }
      }
    }
  }

  &--transactions-history-withdrawal {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 1.45fr 0.8fr 1.45fr 1.45fr 0.4fr 0.9fr 0.9fr;
    }
  }

  &--transactions-history-fiat {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 1.45fr 0.8fr 1.45fr 1.45fr 0.4fr 0.9fr 0.9fr;
    }
  }

  &--transactions-history-new {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 0.6fr 1.45fr 0.45fr 0.7fr 0.45fr 0.75fr 1.2fr 0.9fr 1.2fr;
    }
  }

  &--transactions-history-apartment {
    .tr {
      gap                   : 8px;
      grid-template-columns : 1.3fr 2fr 0.6fr 1.45fr 1.2fr 0.6fr 0.9fr;
    }
  }

  &--transactions-history-convert {
    .tr {
      gap                   : 8px;
      grid-template-columns : 0.3fr 0.9fr 1.3fr 0.8fr 0.5fr 0.5fr 1fr 1.3fr 1fr;
    }
  }

  &--trade-history {
    .tr {
      grid-template-columns : 1.55fr 0.7fr 0.75fr 1.2fr 0.9fr 0.9fr 1.1fr;
      gap                   : 0 5px;
    }

    .table-header {
      @media screen and (max-width : 767px) {
        .td {
          display : none;

          &:nth-child(2),
          &:nth-child(3) {
            display : block;
          }

          &--right {
            text-align : left;
          }
        }
      }
    }
  }

  &--dividends {
    .tr {
      grid-template-columns : 1.8fr 1.5fr 1fr 1fr;
    }

    @media screen and (max-width : 767px) {
      .tr {
        align-items : flex-start;
      }
      .table-body .td + .td {
        margin-top : 8px;
      }
      .td-hidden-name {
        width      : 141px;
        text-align : left;
      }
    }
  }

  &--mt {
    margin-top : 40px;
  }
}

.td-actions {
  display : flex;
  gap     : 16px;
  @media screen and (max-width : 767px) {
    width : 100%;
  }
}

.td--right {
  .td-actions {
    justify-content : flex-end;
  }
}

.td-status {
  font-weight : 500;
  font-size   : 16px;
  line-height : 180%;

  &--success {
    color : #25A353;
  }

  &--in-progress {
    color : #CA8000;
  }

  &--canceled {
    color : #D1513A;
  }

  &--error {
    color : #E41472;
  }
}

.table-info {
  margin-top  : 40px;
  text-align  : center;
  font-weight : 500;

  &--hide-mob {
    @media screen and (max-width : 767px) {
      display : none;
    }
  }
}

.td-wrapper {
  display : flex;
  gap     : 8px;

  button {
    font-weight : 500;
    font-size   : 16px;
    color       : #E41472;
    @media screen and (max-width : 1280px) {
      font-size : 12px;
    }
    @media screen and (max-width : 767px) {
      font-size : 16px;
    }
  }
}

.notification-message {
  font-size   : 16px;
  font-weight : 500;
  line-height : 180%;

  .link {
    margin-top : 4px;
  }
}

.kyc-form {
  max-width : 620px;
  margin    : 0 auto;
}

.kyc-status {
  display         : flex;
  align-items     : center;
  justify-content : center;
  gap             : 8px 16px;
  font-weight     : 500;
  font-size       : 20px;
  line-height     : 180%;
}

.popup-loader-wrapper {
  display         : flex;
  justify-content : center;
  margin          : 16px 0;
}

.loader-icon {
  display   : flex;
  width     : 33px;
  animation : spin 2000ms infinite linear;
}

@keyframes spin {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

.tfa-wrapper {
  max-width : 592px;
}

.tfa-code {
  display               : grid;
  grid-template-columns : 1fr 180px;
  gap                   : 16px;
  margin-bottom         : 16px;
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }
}

.tfa-option {
  display         : flex;
  align-items     : center;
  justify-content : space-between;

  &__name {
    font-weight : 500;
    font-size   : 18px;
  }

  & + .tfa-option {
    margin-top : 20px;
  }
}

.qr {
  display : flex;
  margin  : 0 auto 24px auto;
  width   : 135px;

  img,
  object,
  iframe {
    width : 100%;
  }
}

.popup-greeting {
  text-align : center;
  padding    : 95px 0;

  &__btn {
    margin-top : 48px;
    @media screen and (max-width : 767px) {
      margin-top : 40px;
    }
  }

  @media screen and (max-width : 991px) {
    padding : 50px 0;
  }
  @media screen and (max-width : 767px) {
    padding : 20px 0;
  }
}

.my-properties-list {
  margin    : 0 auto;
  width     : 842px;
  max-width : 100%;

  &--active-orders {
    width : 948px;
  }
}

.my-properties {
  display : flex;
  gap     : 24px 60px;

  & + .my-properties {
    margin-top : 40px;
  }

  @media screen and (max-width : 1199px) {
    gap       : 16px 30px;
    flex-wrap : wrap;
  }
  @media screen and (max-width : 620px) {
    width  : 276px;
    margin : 0 auto;
  }

  &__img {
    width    : 276px;
    position : relative;
  }

  .card-slider-item {
    height : 276px;
  }

  &__info {
    display        : flex;
    flex-direction : column;
    flex           : 1 1 auto;
    max-width      : 300px;
    padding        : 18px 0;
    @media screen and (max-width : 767px) {
      max-width : 100%;
    }
    @media screen and (max-width : 620px) {
      padding : 0;
    }

    .market-card-options {
      margin-top : auto;
      @media screen and (max-width : 620px) {
        margin-top : 24px;
      }
    }

    .card-options {
      &__name {
        font-size : 12px;
      }

      &__value {
        font-weight : 600;
      }

      & + .card-options {
        margin-top : 8px;
      }
    }
  }

  &__doc {
    display     : flex;
    align-items : flex-end;
    width       : 170px;
    padding     : 18px 0;
    @media screen and (max-width : 1199px) {
      justify-content : flex-start;
      width           : 100%;
      margin-top      : 8px;
      padding         : 0;
    }

    .select__current {
      padding : 0 14px;
    }

    .select__drop {
      min-width : 275px;
      left      : unset;
      right     : 0;
      @media screen and (max-width : 1199px) {
        left  : 0;
        right : unset;
      }
    }
  }

  &__actions {
    display     : flex;
    align-items : flex-end;
    width       : 276px;
    padding     : 18px 0;
    @media screen and (max-width : 1199px) {
      justify-content : flex-start;
      width           : 100%;
      margin-top      : 8px;
      padding         : 0;
      .form-btns {
        width : 276px;
      }
    }
    @media screen and (max-width : 767px) {
      .form-btns {
        width : 100%;
      }
    }
  }
}

.form-btns {
  display : flex;
  gap     : 0 16px;
}

.whishlist {
  max-width : 908px;
  margin    : 0 auto;

  .market-card {
    width   : 33.3%;
    padding : 0 20px;

    .card-slider-item {
      height : 276px;
    }

    @media screen and (max-width : 1199px) {
      padding : 0 11px;
    }
    @media screen and (max-width : 767px) {
      width : 50%;
    }
    @media screen and (max-width : 545px) {
      width   : 276px;
      padding : 0;
    }
  }
}

.whishlist-row {
  display   : flex;
  flex-wrap : wrap;
  gap       : 40px 0;
  margin    : 0 -20px;
  @media screen and (max-width : 1199px) {
    margin : 0 -11px;
  }
  @media screen and (max-width : 545px) {
    flex-direction : column;
    align-items    : center;
    margin         : 0;
  }
}

.doc-action {
  max-width     : calc(100% - 54px);
  width         : 632px;
  margin        : 24px auto 0 auto;
  padding       : 24px;
  background    : #FFF;
  border-radius : 15px;
}

.doc-panel-box {
  width         : 100%;
  background    : #FFF;
  border-radius : 15px;

  .container {
    width : 1286px;
  }

  @media screen and (max-width : 767px) {
    padding : 0 7px;
  }
}

.doc-panel {
  display     : flex;
  align-items : center;
  padding     : 24px 0;
  gap         : 13px;
  @media screen and (max-width : 767px) {
    padding : 10px 0;
  }

  &__back {
    display         : flex;
    justify-content : center;
    margin-right    : 22px;
    width           : 22px;
    @media screen and (max-width : 767px) {
      width        : 12px;
      margin-right : 0;
    }
  }

  &__title {
    font-size   : 20px;
    font-weight : 600;
    @media screen and (max-width : 767px) {
      font-size : 12px;
    }
  }

  &__action {
    display     : flex;
    width       : 36px;
    margin-left : auto;
    @media screen and (max-width : 767px) {
      width : 19px;
    }
  }
}

.doc-frame {
  max-width  : 1240px;
  margin-top : 40px;

  img,
  iframe {
    max-width : 100%;
  }

  @media screen and (max-width : 767px) {
    margin-top : 21px;
    padding    : 0 27px;
  }
}

.order-option {
  margin-top : 16px;
}

.order-option {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  font-weight     : 500;
  font-size       : 16px;
  line-height     : 180%;

  & + .order-option {
    margin-top : 16px;
  }

  &--type2 {
    padding : 4px 0;
  }

  &__value {
    word-break : break-word;

    span {
      margin-left : 4px;
      color       : var(--text-color2);
    }

    &--mob-one-line {
      @media screen and (max-width : 767px) {
        overflow      : hidden;
        max-width     : 100%;
        white-space   : nowrap;
        text-overflow : ellipsis;
      }
    }
  }

  &__name {
    &--bigger {
      font-size : 18px;
    }

    &--type2 {
      display     : flex;
      align-items : center;
      gap         : 16px;
    }

    &--color2 {
      color : var(--text-color2);
    }
  }

  &__name-value {
    color     : var(--text-color2);
    font-size : 16px;
  }

  &--mob-column {
    @media screen and (max-width : 767px) {
      flex-direction : column;
      align-items    : flex-start;
      gap            : 4px;
    }
  }
}

.balance-line {
  display         : flex;
  align-items     : center;
  justify-content : flex-end;
  margin-top      : 4px;
  gap             : 10px;
  font-size       : 16px;

  &__value {
    color : var(--text-color2);
  }

  &--start {
    justify-content : flex-start;
  }

  &--smaller {
    font-size : 14px;
  }
}

.invest-container {
  width     : 874px;
  max-width : 100%;
  margin    : 32px auto 0 auto;
}

.invest-item {
  & + .invest-item {
    margin-top : 40px;
  }

  &__content {
    margin-top : 24px;

    &--type2 {
      margin-top : 8px;
    }
  }

  @media screen and (max-width : 1199px) {
    .block-step {
      position : relative;
      top      : 5px;
      left     : 0;
      margin   : 0;
    }
    .form-group {
      display     : flex;
      align-items : flex-start;
      gap         : 16px 40px;
    }
  }
}

.invest-form {
  max-width   : 706px;
  margin-left : auto;

  .form-footer {
    max-width : 566px;
  }

  &__item {
    & + .invest-form__item {
      margin-top : 16px;
    }
  }
}

.invest-data {
  display               : grid;
  align-items           : center;
  grid-template-columns : 231px 1fr;
  gap                   : 8px 60px;

  &__name {
    font-size   : 16px;
    font-weight : 500;
  }

  &__value {
    font-size   : 16px;
    font-weight : 600;

    &--type2 {
      color       : var(--text-color2);
      font-weight : 500;
    }
  }

  @media screen and (max-width : 767px) {
    display   : flex;
    flex-wrap : wrap;
    gap       : 8px;
    .input {
      width : 100%;
    }
    &__value {
      text-align : right;
    }
    &--type2 {
      .invest-data__name {
        width : calc(50% - 4px);
      }

      .invest-data__value {
        width : calc(50% - 4px);
      }
    }
  }
}

.offer {
  display : flex;
  gap     : 32px;

  &__img {
    display : flex;
    flex    : 0 0 134px;
    height  : 134px;

    img {
      width         : 100%;
      height        : 100%;
      object-fit    : cover;
      border-radius : 15px;
    }
  }

  &__info {
    width     : 100%;
    max-width : 280px;
  }

  .card-options {
    font-size : 16px;
    @media screen and (max-width : 575px) {
      flex-direction : column;
      align-items    : flex-start;
    }
  }
}

.content-text {
  p {
    margin-bottom : 24px;
    font-size     : 16px;
    line-height   : 1.8;
    font-weight   : 500;
  }

  h2,
  h3,
  h4 {
    font-weight : 600;
    margin-top  : 24px;
  }

  h4 {
    font-size : 16px;
  }
}

.content-checkbox {
  .checkbox {
    margin-top : 16px;
  }
}

.doc-list {
  display         : flex;
  justify-content : space-between;
  align-items     : flex-end;
  margin-top      : 32px;
  @media screen and (max-width : 767px) {
    flex-wrap : wrap;
  }

  &__btn {
    display         : flex;
    justify-content : flex-end;
    @media screen and (max-width : 767px) {
      width           : 100%;
      margin-top      : 24px;
      justify-content : center;
    }
  }
}

.doc-item {
  display     : flex;
  align-items : center;
  gap         : 16px;

  &__icon {
    display : flex;
  }

  p {
    margin-bottom : 0;
  }

  & + .doc-item {
    margin-top : 16px;
  }
}

.step {
  text-align : center;
  margin     : 0 auto;

  &__number {
    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 45px;
    height          : 45px;
    margin          : 0 auto;
    border          : 1px solid #B6B6B6;
    color           : #B6B6B6;
    border-radius   : 50%;
    font-weight     : 700;
    font-size       : 20px;
  }

  &__text {
    max-width   : 170px;
    margin      : 8px auto 0 auto;
    font-weight : 600;
    font-size   : 16px;
    line-height : 180%;
  }

  &.active {
    .step__number {
      border-color : var(--main-color);
      color        : var(--main-color);
    }
  }

  & + .step {
    margin-top : 40px;
  }
}

.sign-doc {
  width         : 722px;
  margin-top    : 24px;
  max-width     : calc(100% - 52px);
  background    : #FFF;
  border-radius : 15px;
  padding       : 24px 36px;

  &__title {
    text-align : center;
    color      : var(--text-color-main);
  }

  &__frame {
    height        : 200px;
    border-bottom : 1px solid var(--main-color);
  }

  &__btn {
    display         : flex;
    justify-content : flex-end;
    margin-top      : 24px;
    @media screen and (max-width : 767px) {
      .button {
        width : 150px;
      }
    }

    .button {
      background : #E41472;
    }
  }
}

.convert-box {
  max-width : 620px;
  margin    : 0 auto;
}

.convert-icon {
  display : flex;
  width   : 13px;
  margin  : 24px auto 16px auto;
}

.confirm-block {
  text-align  : center;
  line-height : 1.5;

  &__title {
    font-weight : 600;
    font-size   : 16px;

    & + .block-title {
      margin-top : -4px;
    }
  }
}

.convert-coins {
  display         : flex;
  justify-content : center;
  margin-top      : 24px;
  gap             : 0 27px;

  &__icon {
    display : flex;
    width   : 12px;
  }

  &__name {
    font-weight : 600;
    font-size   : 22px;
  }
}

.operation-form {
  max-width : 594px;
}

.instruction {
  margin-top : 40px;

  &__header {
    text-align : center;

    .block-title + .block-subtitle {
      margin-top : 8px;
    }
  }
}

.instruction-content {
  margin-top : 40px;

  &__top {
    margin-bottom : 32px;

    .instruction-text {
      margin-top : 24px;
    }
  }

  &__group {
    margin-bottom : 40px;

    &:last-child {
      margin-bottom : 0;
    }
  }
}

.instruction-text {
  margin-top  : 4px;
  font-size   : 16px;
  font-weight : 500;
  line-height : 180%;

  p + p {
    margin-top : 16px;
  }
}

.instruction-options {
  margin-top : 16px;
}

.instruction-option {
  display               : grid;
  gap                   : 4px 32px;
  grid-template-columns : 200px 1fr;
  font-weight           : 500;
  font-size             : 16px;
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }

  &__name {
    color : var(--text-color2);
  }

  & + .instruction-option {
    margin-top : 12px;
  }
}

.instruction-list {
  margin-top  : 30px;
  font-size   : 16px;
  font-weight : 500;

  li {
    display : flex;
    gap     : 16px;

    & + li {
      margin-top : 8px;
    }
  }
}

.list-check {
  display   : flex;
  flex      : 0 0 15px;
  min-width : 15px;
}

.radio-group {
  margin-top : 16px;
}

.paymernt-method {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  margin-top      : 24px;
  flex-wrap       : wrap;
  gap             : 16px 0;
  @media screen and (max-width : 767px) {
    justify-content : center;
  }

  &__name {
    display     : flex;
    gap         : 16px;
    font-weight : 500;
    font-size   : 18px;
    line-height : 180%;

    span {
      color     : var(--text-color2);
      font-size : 16px;
    }

    @media screen and (max-width : 767px) {
      width           : 100%;
      justify-content : center;
      text-align      : center;
    }
  }
}

.card-row {
  display               : grid;
  grid-template-columns : 1fr 1fr;
  gap                   : 16px;
  @media screen and (max-width : 767px) {
    grid-template-columns : 1fr;
  }
}

.network-name {
  font-weight : 400;
  font-size   : 10px;
  color       : #8D8D8D;
}

.wallet-address {
  display     : flex;
  align-items : center;
  gap         : 16px;

  &__value {
    max-width   : 100%;
    word-break  : break-word;
    font-weight : 500;
    font-size   : 18px;
    line-height : 180%;
    color       : var(--text-color2);

    &--type2 {
      color : var(--text-color-main);
    }
  }
}

.deposit-address {
  display     : flex;
  align-items : center;
  margin-top  : 18px;
  gap         : 40px;

  &__qr {
    display : flex;
    width   : 135px;

    img,
    iframe {
      width : 100%;
    }
  }

  &__info {
    //margin-top: 15px;
  }
}

.my-token {
  display         : flex;
  align-items     : flex-start;
  justify-content : space-between;
  flex-wrap       : wrap;
  gap             : 24px;

  .offer {
    gap : 40px;
  }

  &__info {
    display         : flex;
    align-items     : flex-end;
    justify-content : flex-end;
    gap             : 24px 80px;
    flex            : 1 1 0;

    .market-card-options {
      width      : 276px;
      margin-top : 0;
    }

    .card-options {
      &__name {
        font-size : 12px;
      }

      &__value {
        font-size : 16px;
      }

      & + .card-options {
        margin-top : 4px;
      }
    }

    @media screen and (max-width : 1240px) {
      width           : 100%;
      flex            : 0 0 100%;
      justify-content : flex-start;
    }
    @media screen and (max-width : 767px) {
      flex-wrap : wrap;
      .market-card-options {
        width : 100%;
      }
    }
  }

  &__doc {
    width : 175px;

    .select__drop {
      min-width : 275px;
      left      : unset;
      right     : 0;
      @media screen and (max-width : 1199px) {
        left  : 0;
        right : unset;
      }
    }

    @media screen and (max-width : 767px) {
      width : 100%;
      .select__current {
        justify-content : center;
      }
    }
  }

  & + .my-token {
    margin-top : 40px;
  }

  @media screen and (max-width : 767px) {
    .offer {
      gap : 24px;
    }
  }
}

.wallet-table {
  &--type2 {
    margin-top : 40px;
  }
}

.faq-wrapper {
  width     : 740px;
  max-width : 100%;
  margin    : 0 auto;

  &--full {
    width : 100%;
  }
}

.faq-more {
  display         : flex;
  justify-content : center;
  margin-top      : 24px;
}

.terms-wrapper {
  //max-width: 1172px;
  margin       : 0 auto;
  padding-left : 110px;
  @media screen and (max-width : 1199px) {
    padding : 0 20px;
  }
  @media screen and (max-width : 991px) {
    padding : 0 0;
  }
}

.terms-block {
  display               : grid;
  grid-template-columns : 1fr 308px;
  gap                   : 40px 124px;
  margin-top            : 24px;
  @media screen and (max-width : 991px) {
    grid-template-columns : 1fr;
    gap                   : 40px;
  }
}

.terms-content {
  color : #000;

  p {
    margin-bottom : 32px;
    font-weight   : 500;
    font-size     : 16px;
    line-height   : 180%;

    &:last-child {
      margin-bottom : 0;
    }
  }
}

.contact {
  text-align  : center;
  color       : #000;
  line-height : 180%;

  .link {
    justify-content : center;
  }

  &__text {
    margin-top  : 16px;
    font-weight : 500;
    font-size   : 16px;
  }

  &__email {
    margin-top : 24px;
  }

  .link {
    margin : 0 auto;
  }

  &__email-block {
    display         : flex;
    align-items     : center;
    justify-content : center;
    margin-top      : 24px;
    gap             : 8px 24px;

    .link {
      margin    : 0;
      font-size : 24px;
    }

    .contact__email {
      margin : 0;
    }

    @media screen and (max-width : 767px) {
      flex-direction : column;
    }
  }

  &--help {
    max-width   : 620px;
    margin      : 0 auto;
    padding-top : 8px;

    .contact__text {
      font-weight : 400;
    }
  }
}

.input-amount {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  margin-bottom   : 16px;

  &__name {
    font-size   : 16px;
    font-weight : 500;
  }

  &__value {
    padding     : 8px 0;
    font-size   : 18px;
    font-weight : 600;
  }
}

.cookie-list, .popup-list {
  max-height    : 430px;
  overflow      : auto;
  font-size     : 16px;
  padding-right : 4px;

  &::-webkit-scrollbar {
    width : 6px;
  }

  &::-webkit-scrollbar-track {
    background-color : #F3F3F3;
    border-radius    : 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color : var(--main-color);
    border-radius    : 3px;
  }

  p {
    + p {
      margin-top : 24px;
    }

    strong {
      font-weight : 600;
    }
  }

  ul {
    margin-top  : 16px;
    margin-left : 20px;

    strong {
      font-weight : 600;
    }

    li + li {
      margin-top : 12px;
    }

    & + p {
      margin-top : 24px;
    }
  }

  ol {
    margin-top          : 24px;
    margin-left         : 20px;
    list-style-type     : decimal;
    list-style-position : outside;
  }

  &--in-profile-preview {
    max-height : 50vh;
  }
}

.green {
  color : #25A353;
}

.red {
  color : #E41472;
}

.orange {
  color : #D1513A;
}

.agreement-signature {
  font-family : 'Give You Glory', Serif, serif;
  font-weight : bold;
  color       : #0202B1;
}

@import 'ui-modifiers.scss';
