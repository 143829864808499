.realty-features-box {
	@media screen and (min-width: 768px) {
		.realty-invest {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}
}

.realty-block {
	.featuresButton {
		background: #B6B6B6;
		border: 1px solid white;
		color: white;
	}
	.button--type4 .fill path {
		fill: white;
	}
}

.realty-progress--custom {
	width: 100%;
}

.container .popup-window-invest-custom {
	overflow: auto;
}

