.select--type-z-index {
  z-index: unset;
}

.card-options .color-green {
  color: green;
}

.card-options .color-red {
  color: red;
}

.add-to-fav-custom .stroke path {
  stroke: red;
  transition: 0.2s;
}

.realty-actions .object-color-custom .stroke path {
  stroke: red;
  transition: 0.2s;
}

.select__current--custom {
  text-transform: capitalize;
}

.object-label--custom {
  z-index: unset;
}

.object-wishlist--custom {
  z-index: unset;
}

.profile__bar--custom {
  z-index: 4;
}

.subtitle-custom {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
  display: flex;
  align-items: center;
  color: #25252E;
  margin-bottom: 40px;
}

.input-name-flex-start {
  text-align: start;
}

.text-bolt-custom {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
  color: #25252E;
}

.popup-body-custom {
  position: relative;
  bottom: -10px;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  letter-spacing: 1.1px;
}

.frame-wrapper {
  position: absolute;
  z-index: 10;
  width: 102%;
  height: 99%;
  top: 0;
}

.select-current-custom {
  white-space: nowrap;
}

.my-properties-custom {
  gap: 24px 48px;
}

.card-options .color-block {
  color: #E41472;
}

.wrapper-popup-invest {
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

.order-option-custom {
  &:first-child {
    margin-top: 0;
  }
}

.input-name-custom {
  text-align: start;
}

.step__text--custom {
  width: 100%;
}

.checkbox__text--custom {
  word-break: break-word;
  word-wrap: break-word;
}

.doc-item__icon--custom {
  min-width: 16px;
}

.doc-list__item-custom {
  padding-right: 10px;
}

.ul-custom {
  a {
    word-break: break-all;
  }
}